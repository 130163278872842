import cn from 'classnames';

import { CircledX, Icon, Info, Sparkles } from '@/primitives/Icons';

import styles from './InfoMessage.module.css';

type Variant = 'default' | 'ai' | 'error';

const variantsMap: Record<Variant, string> = {
  default: cn(styles.gray),
  ai: cn(styles.blue, styles.rounded, styles.snug),
  error: cn(styles.red, styles.rounded, styles.snug),
};

const iconMap: Record<Variant, React.ReactNode> = {
  default: <Info />,
  ai: <Sparkles />,
  error: <CircledX />,
};

const colorMap: Record<Variant, string | undefined> = {
  default: undefined,
  ai: 'var(--blue-5)',
  error: 'var(--red-4)',
};

const sizeMap: Record<Variant, number> = {
  default: 2,
  ai: 2,
  error: 3,
};

export const InfoMessage = ({
  message,
  variant = 'default',
}: {
  message: string;
  children?: React.ReactNode;
  variant?: Variant;
}) => {
  return (
    <div className={cn(styles.info, variantsMap[variant])}>
      <p className="flexAlign gap-xs">
        <Icon
          icon={iconMap[variant]}
          className="noShrink"
          color={colorMap[variant]}
          size={sizeMap[variant]}
        />
        {message}
      </p>
    </div>
  );
};
