import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { api } from '@/api/backend';
import { useCutlistState, useSetTitle, useTitle } from '@/api/store';
import { onFocusSelect } from '@/hooks';
import { Input } from '@/primitives/Input';

import { CutlistAgentButton } from './CutlistAgentButton';
import { CutlistOwnerButton } from './CutlistOwnerButton';
export const AgentQuoteHeader = () => {
  const title = useTitle();
  const setTitle = useSetTitle();
  const { t } = useTranslation();
  const shortId = useCutlistState((state) => state.shortId);
  const orderId = useCutlistState((state) => state.orderId);
  const location = useLocation();

  const canAssignOwner = location.pathname.includes('/quote/parts');

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (orderId) {
      api.updateCutlistTitle(orderId, e.currentTarget.value);
    }
  };

  return (
    <div className="opposites">
      <div>
        <label htmlFor="cutlist-title">{shortId ?? ''}</label>
        <Input
          onInput={(e) => setTitle(e.currentTarget.value)}
          value={title}
          className="title"
          placeholder={t('cutlist-form.field.orderTitle.placeholder')}
          id="cutlist-title"
          onFocus={onFocusSelect}
          onBlur={onBlur}
        />
      </div>
      <div className="stack gap-xs">
        <div className="alignSelfEnd">
          <CutlistAgentButton />
        </div>
        <div className="alignSelfEnd">
          <CutlistOwnerButton readOnly={!canAssignOwner} />
        </div>
      </div>
    </div>
  );
};
