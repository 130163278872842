import { EdgeGroove, MaterialGroup } from '@cutr/constants/cutlist';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { useFeatureFlag } from '@/api/featureFlags';
import { useCutlistState } from '@/api/store';
import { Button } from '@/primitives/Button';
import { Checkbox } from '@/primitives/Checkbox';
import { Input } from '@/primitives/Input';

import MaterialGroupCard from '../PartTableCommon/MaterialGroupCard';
import { MaterialRemoval, MaterialUpdate } from '../PartTableCommon/types';
import styles from './EdgeProcessingConfiguration.module.css';
import {
  EdgebandingProvider,
  EdgeGroovesProvider,
  EdgeSelectorProvider,
  useEdgebanding,
  useEdgeGrooves,
  useEdgeSelector,
} from './EdgeProcessingContext';
import { EdgesSelector } from './EdgeSelector';
import { GroovePreview } from './GroovePreview';
import { useSelectedPartsContext } from './SelectedPartsContext';

export const EdgeProcessingConfiguration = () => {
  const hasLlmFlowEnabled = useFeatureFlag('invofox-llm-flow');

  if (hasLlmFlowEnabled) return null;

  return (
    <EdgeSelectorProvider>
      <EdgebandingProvider>
        <EdgeGroovesProvider>
          <EdgeProcessingContent />
        </EdgeGroovesProvider>
      </EdgebandingProvider>
    </EdgeSelectorProvider>
  );
};

const EdgeProcessingContent = () => {
  const { t } = useTranslation();
  const { edgesToApply } = useEdgeSelector();
  const { edgeband, roundedEdgeband, clearState } = useEdgebanding();
  const { groovesPerEdge } = useEdgeGrooves();
  const { position, dimensions } = groovesPerEdge['all'];
  const { selectedParts } = useSelectedPartsContext();
  const { setPart } = useCutlistState();

  const handleApplyEdgeProcessing = (e: React.FormEvent) => {
    e.preventDefault();

    selectedParts.forEach((part) => {
      const newPart = { ...part };

      ['length1', 'length2', 'width1', 'width2'].forEach((edge) => {
        // @ts-expect-error
        if (['off', 'mixed'].includes(edgesToApply[edge])) return;

        if (edgeband) {
          newPart.edgebanding = {
            ...{
              length1: null,
              length2: null,
              width1: null,
              width2: null,
            },
            ...newPart.edgebanding,
            [edge]: edgeband,
          };

          if (roundedEdgeband) {
            newPart.roundedEdgeband = {
              ...newPart.roundedEdgeband,
              [edge]: true,
            };
          }
        }

        if (position !== 'none') {
          newPart.grooves = {
            ...newPart.grooves,
            [edge]: { position, ...dimensions },
          };
        }
      });

      setPart(newPart);
    });

    toast.success(t('agent.toasts.edgeProcessingApplied') as string);

    clearState();
  };

  const getButtonTooltip = () => {
    if (selectedParts.length === 0) return t('agent.atLeastOnePartTooltip');
    if (Object.values(edgesToApply).every((v) => v === 'off'))
      return t('agent.atLeastOneEdgeTooltip');
    return undefined;
  };

  const edgeProcessingButtonEnabled =
    selectedParts.length !== 0 &&
    !Object.values(edgesToApply).every((v) => v === 'off') &&
    (edgeband || position !== 'none');

  return (
    <form onSubmit={handleApplyEdgeProcessing}>
      <h3>{t('agent.quoteFlow.edgeProcessing')}</h3>
      <p>{t('agent.quoteFlow.edgeProcessingDescription')}</p>
      <div className={styles.grid}>
        <div>
          <EdgeGrooveConfiguration />
          <EdgebandConfiguration />
          <div className={styles.previewGrid}>
            <div className={styles.preview}>
              <h4 className={styles.subtitle}>{t('agent.preview.title')}</h4>
              <GroovePreview position={position ?? 'none'} />
            </div>
            <div className={styles.preview}>
              <h4 className={styles.subtitle}>
                {t('agent.preview.toApplyTo')}
              </h4>
              <EdgesSelector>
                <strong>{t('agent.preview.willApplyTo')}</strong>
                <br />
                {t('agent.preview.selectedPartsNr', {
                  count: selectedParts.length,
                })}
              </EdgesSelector>
            </div>
          </div>
          <p />
          <Button
            type="submit"
            className="outline"
            tooltip={getButtonTooltip()}
            disabled={!edgeProcessingButtonEnabled}
          >
            {t('agent.applyEdgeProcessing')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export const EdgeGrooveConfiguration = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h4 className={styles.subtitle}>{t('agent.edgeGroove.title')}</h4>
      <EdgeGroovePosition />
      <EdgeGrooveDimensions />
    </div>
  );
};

const EdgeGroovePosition = () => {
  const { t } = useTranslation();
  const { groovesPerEdge, setEdgeGroove } = useEdgeGrooves();
  const { position } = groovesPerEdge['all'];

  const handlePositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEdgeGroove('all', {
      position: event.target.value as EdgeGroove['position'],
      dimensions: groovesPerEdge['all'].dimensions,
    });
  };

  return (
    <div className={styles.inputContainer}>
      <strong>{t('agent.edgeGroove.position.label')}</strong>
      <div className="flexAlign" style={{ paddingTop: 'var(--space-xs)' }}>
        {['none', 'top', 'side', 'bottom'].map((option) => (
          <div className="flexAlign gap-xs" key={option}>
            <input
              name="groove-position"
              id={`groove-position-${option}`}
              type="radio"
              value={option}
              style={{
                appearance: 'auto',
                margin: 0,
                accentColor: 'var(--primary)',
              }}
              onChange={(e) => handlePositionChange(e)}
              checked={position === option}
              required
            />
            <label htmlFor={`groove-position-${option}`}>
              {t(`agent.edgeGroove.position.${option}`)}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const EdgeGrooveDimensions = () => {
  const { t } = useTranslation();
  const { groovesPerEdge, setEdgeGroove } = useEdgeGrooves();
  const { position, dimensions } = groovesPerEdge['all'];
  const { offset, width, depth } = dimensions;

  const handleDimensionChange =
    (dimension: 'A' | 'B' | 'C') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const cleanedValue = Number(event.target.value);
      setEdgeGroove('all', {
        position,
        dimensions: { ...dimensions, [dimension]: cleanedValue },
      });
    };

  const getPlaceholderA = (position: EdgeGroove['position']) => {
    if (position === 'top')
      return t('agent.edgeGroove.dimension.placeholderOffsetSide');
    if (position === 'side')
      return t('agent.edgeGroove.dimension.placeholderOffsetTop');
    if (position === 'bottom')
      return t('agent.edgeGroove.dimension.placeholderOffsetSide');
    return '';
  };

  const getPlaceholderB = () => {
    return t('agent.edgeGroove.dimension.placeholderWidth');
  };

  const getPlaceholderC = () => {
    return t('agent.edgeGroove.dimension.placeholderDepth');
  };

  const dimensionsMeta = [
    {
      key: 'groove-dimension-A',
      dimension: 'offset',
      value: offset,
      placeholder: getPlaceholderA(position ?? 'none'),
    },
    {
      key: 'groove-dimension-B',
      dimension: 'width',
      value: width,
      placeholder: getPlaceholderB(),
    },
    {
      key: 'groove-dimension-C',
      dimension: 'depth',
      value: depth,
      placeholder: getPlaceholderC(),
    },
  ];

  return (
    <div className={styles.inputContainer}>
      <strong>{t('agent.edgeGroove.dimension.label')}</strong>
      <div className="flexAlign" style={{ paddingTop: 'var(--space-xs)' }}>
        {dimensionsMeta.map(({ key, dimension, value, placeholder }) => (
          <div className="flexAlign gap-xs" key={key}>
            <label htmlFor={key} className="textStrong">
              {t(`agent.edgeGroove.dimension.${dimension}`)}
              {position !== 'none' ? (
                '*'
              ) : (
                <span style={{ visibility: 'hidden' }}>*</span>
              )}
            </label>
            <Input
              name="groove-dimension"
              type="number"
              step="0.1"
              id={key}
              required={position !== 'none'}
              value={value}
              placeholder={placeholder}
              disabled={position === 'none'}
              onChange={(e) => {
                handleDimensionChange(dimension as 'A' | 'B' | 'C')(e);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const EdgebandConfiguration = () => {
  const { t } = useTranslation();
  const { edgeband, setEdgeband, roundedEdgeband, setRoundedEdgeband } =
    useEdgebanding();

  const onUpdateEdgeband: MaterialUpdate = (fields) => {
    setEdgeband(fields.edgeband || '', 'all');
  };

  const onRemoveEdgeband: MaterialRemoval = () => {
    setEdgeband('', 'all');
    setRoundedEdgeband(false, 'all');
  };

  const handleRoundedEdgebandChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRoundedEdgeband(event.target.checked, 'all');
  };

  return (
    <div>
      <h4 className={styles.subtitle}>
        {t('agent.edgebandConfiguration.title')}
      </h4>

      <div className={styles.inputContainer}>
        <MaterialGroupCard
          i18nKey="edgeband"
          field="edgeband"
          group={{ edgeband } as MaterialGroup}
          onUpdateMaterial={onUpdateEdgeband}
          onRemoveMaterial={onRemoveEdgeband}
        />
      </div>

      <div className={styles.inputContainer}>
        <Checkbox
          checked={roundedEdgeband}
          onChange={handleRoundedEdgebandChange}
          className="labelBefore"
          disabled={!edgeband}
          isSwitch
        >
          {t('agent.edgebandConfiguration.edgebanding.roundedEdgeband')}
        </Checkbox>
      </div>
    </div>
  );
};
