import { CutlistPartType, Material } from '@cutr/constants/cutlist';
import { SupplierMaterial } from '@cutr/constants/material';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'classnames';
import { nanoid } from 'nanoid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { useLeadDetails } from '@/api/account';
import { agentApi } from '@/api/backend/agent';
import { useMaterialGroupErrorStore, usePartsErrorStore } from '@/api/errors';
import { getMaterial } from '@/api/materials';
import {
  useMaterialGroupState,
  useSaveAllGroupChanges,
} from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';
import { AgentQuoteHeader } from '@/blocks/AgentOrderHeader';
import Card from '@/blocks/Card';
import {
  CutlistEmailPreview,
  hasEmailData,
} from '@/blocks/CutlistEmailPreview';
import { materialDimensions } from '@/blocks/MaterialRow';
import { AgentQuotingPartTable } from '@/interfaces/AgentQuotingPartTable';
import { useRunNesting } from '@/interfaces/Nesting';
import { Options } from '@/interfaces/PartTableCommon/Components';
import { useAiSuggestionWarnings } from '@/interfaces/PartTableCommon/useAiSuggestionChecks';
import { QuotingMaterialGroupTabNav } from '@/interfaces/QuotingMaterialGroupTabNav';
import { Button } from '@/primitives/Button';
import { Download, Icon, Loader } from '@/primitives/Icons';
import { CutlistEmailLoaderType } from '@/queries/loaders';
import { saveBlob } from '@/utils/file';

import styles from './AgentQuoteParts.module.css';

const isDev = import.meta.env.VITE_CUTR_ENV === 'development';

export const AgentQuoteParts = (): JSX.Element => {
  const emailData = useLoaderData() as CutlistEmailLoaderType;
  const hasEmail = hasEmailData(emailData);

  if (hasEmail) {
    return (
      <>
        <AgentQuoteHeader />
        <SubHeader />
        <section className="layout">
          <CutlistEmailPreview />
          <Aside />
        </section>
        <QuotingMaterialGroupTabNav />
        <AgentQuotingPartTable />
      </>
    );
  }

  return (
    <>
      <AgentQuoteHeader />
      <SubHeader />
      <QuotingMaterialGroupTabNav />
      <section className="layout">
        <AgentQuotingPartTable />
        <Aside />
      </section>
    </>
  );
};

export const SubHeader = () => {
  return <Options />;
};

const Aside = () => {
  return (
    <aside>
      <MaterialSummary />
      <Nesting hidden={!isDev} />
    </aside>
  );
};

const Nesting = ({ hidden }: { hidden?: boolean }) => {
  return (
    <div className={cn(hidden && 'hidden')}>
      <NestingHooks />
    </div>
  );
};

const NestingHooks = () => {
  useRunNesting();
  return null;
};

type MaterialSummary = {
  id: string;
  core1: Material;
  variationDimensions: string[];
};

const MaterialSummary = () => {
  const { t } = useTranslation();
  const materialGroups = useMaterialGroupState((state) => state.groups);
  const hasMaterials = useCutlistState((state) => state.hasMaterials);

  const savedMaterials = React.useMemo(() => {
    const materials = materialGroups
      .filter((group) => group.ulid)
      .map((group) => {
        const variationDimensions =
          group.automaticSheetSizeMaterials?.map((material) =>
            materialDimensions(getMaterial(material) as SupplierMaterial)
          ) || [];

        return {
          id: nanoid(),
          core1: getMaterial(group?.core1),
          variationDimensions,
        } as MaterialSummary;
      })
      .filter((item) => item.core1);

    return materials;
  }, [materialGroups, hasMaterials]);

  return (
    <Card>
      <div className={styles.divider}>
        <h3>{t('agent.quoteFlow.materialSummaryTitle')}</h3>

        {savedMaterials.length > 0 ? (
          <ol className={styles.materialSummary}>
            {savedMaterials.map(({ id, core1, variationDimensions }) => {
              const core1Dimensions = materialDimensions(core1);
              const allDimensions = Array.from(
                new Set([core1Dimensions, ...variationDimensions])
              );
              return (
                <li key={id}>
                  <div className="stack gap-xxs">
                    <span>{core1.name}</span>
                    {allDimensions.map((itemDimension) => (
                      <small className="textStrong" key={itemDimension}>
                        {itemDimension}
                      </small>
                    ))}
                  </div>
                </li>
              );
            })}
          </ol>
        ) : (
          <p>{t('agent.quoteFlow.noMaterials')}</p>
        )}
      </div>

      <ActionButtons />
    </Card>
  );
};

const ActionButtons = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { shortId } = useCutlistState();
  const [isDownloadingHPO, setIsDownloadingHPO] = React.useState(false);
  const [isSavingGroups, setIsSavingGroups] = React.useState(false);
  const { email } = useLeadDetails();
  const queryClient = useQueryClient();
  const [saveAllMaterialGroups, canSave] = useSaveAllGroupChanges();
  const parts = useCutlistState((state) => state.parts);

  const { materialGroupErrors } = useMaterialGroupErrorStore();
  const { partErrors } = usePartsErrorStore();
  const aiSuggestionWarnings = useAiSuggestionWarnings();

  const isCTADisabled = isSavingGroups || isDownloadingHPO || !canSave;

  if (!id) {
    return null;
  }

  const onAction = async () => {
    if (!id) return;

    setIsSavingGroups(true);
    try {
      const count = await saveAllMaterialGroups();
      if (count !== 0) {
        toast.success(t('agent.toasts.materialSettingsSaved'));
      }
    } catch (error) {
      toast.error(t('common.errors.saveOrder') as string);
    } finally {
      setIsSavingGroups(false);

      queryClient.removeQueries({
        queryKey: ['cutlist'],
        exact: false,
        type: 'all',
      });

      queryClient.removeQueries({
        queryKey: ['agent'],
        exact: false,
        type: 'all',
      });
    }
  };

  const downloadHpo = async () => {
    if (!id) return;
    setIsDownloadingHPO(true);

    await onAction();

    try {
      const result = await agentApi.getCutlistHpo(id);
      saveBlob(result, `${shortId}_hpo.xlsx`);
    } catch (error) {
      toast.error(t('agent.toasts.fileDownloadError'));
    } finally {
      setIsDownloadingHPO(false);
    }
  };

  return (
    <div className="stack" style={{ paddingTop: '1rem' }}>
      <Button
        className="outline"
        icon={<Icon icon={<Download />} />}
        onClick={downloadHpo}
        disabled={isDownloadingHPO}
      >
        {isDownloadingHPO ? (
          <Icon icon={<Loader />} />
        ) : (
          t('agent.quoteFlow.downloadHpo')
        )}
      </Button>
      <Button
        onClick={async () => {
          if (!email) {
            toast.error(t('agent.toasts.setCutlistOwner'));
            return;
          }

          if (materialGroupErrors.length > 0) {
            jumpToGroup(materialGroupErrors[0].materialGroupId);
            toast.error(t('agent.errors.groupError'));
            return;
          }

          if (partErrors.length > 0) {
            const part = parts?.find((p) => p.id === partErrors[0].partItemId);
            if (part) {
              jumpToGroup(part.groupId, part.id);
              toast.error(t('agent.errors.partError'));
            }
            return;
          }

          if (aiSuggestionWarnings.length > 0) {
            jumpToGroup(aiSuggestionWarnings[0].materialGroupId);
            toast.warning(t('agent.warnings.core1'));
            return;
          }

          await onAction();
          navigate(`/admin/orders/${id}/quote/price`);
        }}
        disabled={isCTADisabled}
        style={{
          // @ts-ignore
          '--button-bkg': 'var(--agent-accent)',
        }}
      >
        {isSavingGroups ? (
          <Icon icon={<Loader />} />
        ) : (
          t('agent.quoteFlow.partsCallToAction')
        )}
      </Button>
    </div>
  );
};

export const jumpToGroup = (groupId: string, partId?: string) => {
  const { setActive } = useMaterialGroupState.getState();
  const parts = useCutlistState.getState().parts;

  const jump = () => {
    setActive(groupId);

    if (!partId) {
      return;
    }

    Promise.resolve().then(() => {
      const panelType = parts?.find((p) => p.id === partId)?.partType;
      if (!panelType) return;

      const typePrefix: Record<CutlistPartType, string> = {
        panel: 'panelRow',
        strip: 'stripRow',
        sheet: 'sheetRow',
      };

      const prefix = typePrefix[panelType];
      const panelRow = document.getElementById(`${prefix}-${partId}`);
      if (panelRow) {
        panelRow.scrollIntoView({ behavior: 'smooth' });
      }
    });
  };

  jump();
};
