import { useTranslation } from 'react-i18next';

import {
  useMaterialGroupErrorStore,
  useNestingErrorStore,
  usePartsErrorStore,
} from '@/api/errors';
import { useActiveGroup } from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';
import { ErrorMessage } from '@/blocks/ErrorMessage';

export const Errors = () => {
  const { nestingErrors } = useNestingErrorStore();
  const { materialGroupErrors } = useMaterialGroupErrorStore();
  const { t } = useTranslation();

  if (nestingErrors.length === 0 && materialGroupErrors.length === 0)
    return null;

  return (
    <ErrorMessage message={t('cutlist-form.errors.groupsTitle')}>
      {nestingErrors.map((e, id) => (
        <p key={id}>{e}</p>
      ))}
      {materialGroupErrors.map((e, id) => (
        <p key={id}>{e.errorMessage}</p>
      ))}
    </ErrorMessage>
  );
};

export const ActiveGroupPartErrors = () => {
  const { partErrors } = usePartsErrorStore();
  const parts = useCutlistState((state) => state.parts);
  const { t } = useTranslation();
  const activeGroup = useActiveGroup();

  const currentPartErrors = parts
    .filter((p) => p.groupId === activeGroup?.id)
    .map((p) => partErrors.find((e) => e.partItemId === p.id))
    .filter(Boolean);

  if (currentPartErrors.length === 0) return null;

  return (
    <ErrorMessage message={t('cutlist-form.errors.title')}>
      {currentPartErrors.map((e, id) => (
        <p key={id}>{e?.errorMessage}</p>
      ))}
    </ErrorMessage>
  );
};
