import {
  Material,
  MaterialSandwichType,
  sandwichHasHPL,
} from '@cutr/constants/cutlist';
import cn from 'classnames';
import React from 'react';

import { useMaterialSearch } from '@/api/materialSearch';
import {
  useUsedBandingMaterials,
  useUsedCoreMaterials,
  useUsedHplMaterials,
} from '@/api/store';
import { onFocusSelect } from '@/hooks';
import { Icon, Search } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { useCurrentSource } from '@/theme';
import { useDebounce } from '@/utils/hooks';

import styles from './styles.module.css';

interface AutocompleteProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: Material['articleCode'];
  placeholder: string;
  id: string;
  materialType?: 'core' | 'hpl' | 'edgeband'; // falls back to core
  materialSandwichType: MaterialSandwichType;
  inputClass?: string;
  onResults: (results: Material[]) => void;
}

export const Autocomplete = ({
  placeholder,
  value,
  id,
  materialType,
  materialSandwichType,
  inputClass,
  onResults,
  ...props
}: AutocompleteProps) => {
  const [val, setVal] = React.useState<Material['articleCode']>(
    (value as string) || ''
  );
  const [sendTrackBlur, setSendTrackBlur] = React.useState(false);
  const source = useCurrentSource();

  const debouncedVal = useDebounce<string>(val, 100, value);
  let searchResults = useMaterialSearch(debouncedVal, materialType);

  const sandwichWithHPL = sandwichHasHPL(materialSandwichType);
  if (sandwichWithHPL) {
    searchResults = searchResults.filter(
      (material) => material.gluingAndPressingAllowed
    );
  }

  const coreMaterials = useUsedCoreMaterials();
  const hplMaterials = useUsedHplMaterials();
  const edgebandingMaterials = useUsedBandingMaterials();

  const recentMaterials =
    materialType === 'edgeband'
      ? edgebandingMaterials
      : materialType === 'hpl'
      ? hplMaterials
      : coreMaterials;

  // In the case where a customer types in their search, accidentally clicks out of
  // the field and then back in, we don't want to treat that as a search failure.
  // This gives a two second buffer for them to click back in before we track
  // the blur as a failed search.
  React.useEffect(() => {
    if (sendTrackBlur) {
      const cancellation = setTimeout(() => {
        window.analytics.track('Searched for material', {
          searchInput: val,
          field: materialType,
          success: false,
          ticker: source,
          resultCount: searchResults.length,
        });
        setSendTrackBlur(false);
      }, 2000);

      return () => {
        clearTimeout(cancellation);
      };
    }
  }, [sendTrackBlur]);

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVal(String(e.target.value));
  };

  React.useEffect(() => {
    if (!debouncedVal) {
      onResults(recentMaterials);
    } else {
      onResults(searchResults);
    }
  }, [debouncedVal]);

  return (
    <div className={styles.autocomplete}>
      <Input
        id={`autocomplete-input-${id}`}
        icon={<Icon icon={<Search />} />}
        placeholder={placeholder}
        value={val}
        aria-autocomplete="both"
        aria-controls={`autocomplete-${id}`}
        aria-haspopup="listbox"
        role="combobox"
        className={cn(inputClass, 'w-border')}
        onInput={onInput}
        onFocus={(e) => {
          onFocusSelect(e);
          setSendTrackBlur(false);
        }}
        {...props}
      />
    </div>
  );
};
