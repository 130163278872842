import cn from 'classnames';
import React from 'react';

import { Button } from '@/primitives/Button';
import { Icon, Nope } from '@/primitives/Icons';
import { getKeyboardFocusableElements } from '@/utils/a11y';

import styles from './Modal.module.css';
import ReactPortal from './ReactPortal';

export type ModalProps = {
  children: React.ReactNode;
  title: string;
  isOpen: boolean;
  handleClose?: () => void;
  hug?: boolean;
  classes?: string;
};

export const Modal = ({
  children,
  title,
  isOpen = false,
  handleClose,
  hug,
  classes,
}: ModalProps) => {
  const overflow = React.useRef('');
  const modal = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const container = modal.current;

    const closeOnEscapeKey = (e: KeyboardEvent) => {
      const focusedEl = container?.querySelector(
        'input:focus'
      ) as HTMLInputElement;

      if (focusedEl?.type === 'text') return;
      if (e.key === 'Escape' && handleClose) handleClose();
    };

    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => document.body.removeEventListener('keydown', closeOnEscapeKey);
  }, [handleClose]);

  // lock scrolling
  React.useEffect(() => {
    const container = modal.current;
    const focusable = getKeyboardFocusableElements(container);
    const firstFocusable = focusable[0];

    const focusOut = (ev: FocusEvent) => {
      if (!container?.contains(ev.relatedTarget as Node)) {
        firstFocusable.focus();
      }
    };

    if (!isOpen) {
      document.body.style.overflow = overflow.current;
      container?.removeEventListener('focusout', focusOut);
      return;
    }

    overflow.current = document.body.style.overflow;
    document.body.style.overflow = 'hidden';

    container?.setAttribute('tabindex', '0');
    firstFocusable.focus();
    container?.addEventListener('focusout', focusOut);

    return () => container?.removeEventListener('focusout', focusOut);
  }, [isOpen]);

  React.useEffect(() => {
    return () => {
      document.body.style.overflow = overflow.current;
    };
  }, []);

  if (!isOpen) return null;

  return (
    <ReactPortal wrapperId="react-portal-modal-container">
      <div
        aria-modal="true"
        role="dialog"
        tabIndex={-1}
        className={styles.modal}
        onClick={(e) => {
          if (e.target !== e.currentTarget) return;
          handleClose && handleClose();
        }}
      >
        <div
          className={cn(styles.content, hug && styles.hug, classes)}
          ref={modal}
        >
          <div className="opposites">
            <h2>{title}</h2>
            <Button
              tabIndex={-1}
              onClick={handleClose}
              variant="icon"
              className="outline"
              icon={<Icon icon={<Nope />} />}
            />
          </div>

          {children}
        </div>
      </div>
    </ReactPortal>
  );
};
