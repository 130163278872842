import { useTranslation } from 'react-i18next';

import {
  CircledCheckmark2,
  CrossAndCheckmark,
  CutCircle,
  Icon,
} from '@/primitives/Icons';

import { useEdgeSelector } from './EdgeProcessingContext';
import styles from './EdgeSelector.module.css';

export const EdgesSelector = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const { edgesToApply: edges, setEdgesToApply } = useEdgeSelector();

  const ratio = Math.round((1 / 1.64) * 100); // golden ratio

  const edgeOrder = ['width1', 'width2', 'length2', 'length1'] as const;

  type Edges = typeof edges;
  const toggleEdge = (edge: keyof Edges) => {
    setEdgesToApply((prev) => {
      const prevValue = prev[edge];
      const newValue = prevValue === 'on' ? 'off' : 'on';
      const newEdges = { ...prev, [edge]: newValue };
      return newEdges;
    });
  };

  const sidesLabel = {
    width1: 'w1',
    width2: 'w2',
    length2: 'l2',
    length1: 'l1',
  };

  const styleMap = {
    on: styles.active,
    off: undefined,
    mixed: styles.mixed,
  };

  const borderStyleMap = {
    on: 'solid' as const,
    off: 'solid' as const,
    mixed: 'dashed' as const,
  };

  const iconMap = {
    on: <CircledCheckmark2 />,
    off: <CutCircle />,
    mixed: <CrossAndCheckmark />,
  };

  const colorMap = {
    on: 'var(--primary)',
    off: undefined,
    mixed: 'var(--primary)',
  };

  return (
    <div
      className={styles.edgesPreview}
      style={{ '--aspectRatio': ratio } as React.CSSProperties}
    >
      <div
        className={styles.contour}
        style={{
          borderTopColor: colorMap[edges['length2']],
          borderRightColor: colorMap[edges['width2']],
          borderBottomColor: colorMap[edges['length1']],
          borderLeftColor: colorMap[edges['width1']],
          borderTopStyle: borderStyleMap[edges['length2']],
          borderRightStyle: borderStyleMap[edges['width2']],
          borderBottomStyle: borderStyleMap[edges['length1']],
          borderLeftStyle: borderStyleMap[edges['width1']],
        }}
      >
        {edgeOrder.map((edge) => (
          <label key={edge} className={styleMap[edges[edge]]}>
            <Icon
              color={colorMap[edges[edge]]}
              icon={iconMap[edges[edge]]}
              size={3}
            />
            {t(`cutlist-form.field.edgebanding.sides.${sidesLabel[edge]}`)}
            <input
              className={styles.hiddenCheckbox}
              name={`edge-preview`}
              id={`edge-preview-${edge}`}
              type="checkbox"
              onChange={() => toggleEdge(edge)}
              checked={edges[edge] === 'on'}
            />
          </label>
        ))}
      </div>
      <div className={styles.details}>{children}</div>
    </div>
  );
};
