import { GetCutlistsParams } from '@/api/backend/agent';

export const cutlistsKeys = {
  create: ['create'],
  update: ['update'],
  getOne: (cutlistId: string) => ['cutlist', cutlistId],
  getAll: ['cutlists'],
  duplicate: ['duplicate'],
  delete: ['delete'],
  acceptQuote: ['acceptQuote'],
};

export const agentKeys = {
  login: ['agent', 'login'],
  getCutlists: (params: GetCutlistsParams) => [
    'agent',
    'cutlists',
    ...Object.values(params),
  ],
  getPriceForMaterialGroups: (cutlistId: string) => [
    'agent',
    'price',
    'material-groups',
    cutlistId,
  ],
  getPrice: (cutlistId: string) => ['agent', 'price', cutlistId],
  getCutlistEmail: (cutlistId: string) => [
    'agent',
    'cutlist',
    'email',
    cutlistId,
  ],
  getPriceOverride: (
    cutlistId: string,
    quantityOverrides: (number | null)[],
    discountAmount?: number,
    discountPercentage?: number,
    markupAmount?: number,
    markupPercentage?: number
  ) => [
    'agent',
    'price',
    'override',
    cutlistId,
    ...quantityOverrides,
    discountAmount,
    discountPercentage,
    markupAmount,
    markupPercentage,
  ],
  savePriceOverride: (cutlistId: string) => [
    'agent',
    'price',
    'save',
    cutlistId,
  ],
  update: ['agent', 'update'],
  createCutlist: ['agent', 'create'],
  createMaterialGroup: ['agent', 'material-group', 'create'],
  updateMaterialGroup: ['agent', 'material-group', 'update'],
  deleteMaterialGroup: ['agent', 'material-group', 'delete'],
  assignCutlistOwner: ['agent', 'update', 'owner'],
  assignCutlistAgent: ['agent', 'update', 'agent'],
  cancel: ['agent', 'cancel'],
  updateCutlistAddress: ['agent', 'update', 'address'],
  finaliseQuote: ['agent', 'finalise', 'quote'],
  patchCutlist: (cutlistId: string) => ['agent', 'patch', 'cutlist', cutlistId],
  searchOwner: (
    searchQuery: string,
    isModalOpen: boolean,
    hasEdits: boolean
  ) => ['agent', 'search', 'owner', searchQuery, isModalOpen, hasEdits],
  searchAgent: (searchQuery: string) => [
    'agent',
    'search',
    'agent',
    searchQuery,
  ],
  agents: ['agent', 'get', 'agents'],
  createUserLead: ['userLead', 'create'] as const,
  updateUserLead: ['userLead', 'update'] as const,
};
