import { Material, PartGrooves, PartItem } from '@cutr/constants/cutlist';
import { Edgebanding } from '@cutr/constants/cutlist';
import { useTranslation } from 'react-i18next';

import { getMaterial } from '@/api/materials';
import { useCutlistState } from '@/api/store';
import { Button } from '@/primitives/Button';
import {
  CircledX,
  FilledCheckmark,
  Icon,
  WarningTriangle,
} from '@/primitives/Icons';
import { CutCircle } from '@/primitives/Icons';

import styles from './EdgeProcessing.module.css';

export const sides: (keyof Edgebanding)[] = [
  'length1',
  'length2',
  'width1',
  'width2',
];

export const EdgeProcessing = ({ part }: { part: PartItem }) => {
  return (
    <>
      <EdgebandingSides part={part} />
      <EdgeGroovingSides part={part} />
    </>
  );
};

export const EdgebandingSides = ({ part }: { part: PartItem }) => {
  const { setPart } = useCutlistState();
  const { t } = useTranslation();
  const emptyPartEdge = {
    length1: null,
    length2: null,
    width1: null,
    width2: null,
  };

  const handleClearEdgebanding = () => {
    setPart({
      ...part,
      edgebanding: emptyPartEdge,
      roundedEdgeband: {},
      aiMeta: {
        length1AppliedEdgeband: true,
        length2AppliedEdgeband: true,
        width1AppliedEdgeband: true,
        width2AppliedEdgeband: true,
      },
    });
  };

  return (
    <>
      {sides.map((side) => (
        <td key={side}>
          <span className="flexAlign gap-xs">
            <EdgebandSide part={part} side={side} />
          </span>
        </td>
      ))}
      <td>
        <Button
          variant="icon"
          className="delete"
          icon={<Icon icon={<CircledX />} />}
          tooltip={t('agent.clearEdgebanding')}
          onClick={handleClearEdgebanding}
        ></Button>
      </td>
    </>
  );
};

export const EdgeGroovingSides = ({ part }: { part: PartItem }) => {
  const { setPart } = useCutlistState();
  const { t } = useTranslation();

  const emptyGroove = {
    position: 'none' as const,
    offset: 0,
    width: 0,
    depth: 0,
  };

  const handleClearGrooving = () => {
    setPart({
      ...part,
      grooves: {
        length1: { ...emptyGroove },
        length2: { ...emptyGroove },
        width1: { ...emptyGroove },
        width2: { ...emptyGroove },
      },
    });
  };

  return (
    <>
      {sides.map((side) => (
        <td key={side}>
          <span className="flexAlign gap-xs">
            <EdgeGroovingSide part={part} side={side} />
          </span>
        </td>
      ))}
      <td>
        <Button
          variant="icon"
          className="delete"
          icon={<Icon icon={<CircledX />} />}
          tooltip={t('agent.clearGrooving')}
          onClick={handleClearGrooving}
        ></Button>
      </td>
    </>
  );
};

export const EdgebandSide = ({
  part,
  side,
}: {
  part: PartItem;
  side: keyof Edgebanding;
}) => {
  const edgeband = part.edgebanding?.[side];
  const roundedEdgeband = part.roundedEdgeband?.[side];
  const aiSuggestion = part.aiSuggestions?.[`${side}Edgeband`];
  const material = getMaterial(edgeband);

  const edgebandApplied = part.aiMeta?.[`${side}AppliedEdgeband`];

  if (edgeband) {
    return (
      <EdgebandSideInfo
        edgeband={edgeband}
        roundedEdgeband={roundedEdgeband}
        material={material}
      />
    );
  }

  if (edgebandApplied) {
    return <EdgebandSideNone />;
  }

  if (aiSuggestion) {
    return <EdgebandSideAiSuggestion aiSuggestion={aiSuggestion} />;
  }

  return <EdgebandSideNone />;
};

const EdgebandSideInfo = ({
  edgeband,
  roundedEdgeband,
  material,
}: {
  edgeband: Edgebanding[keyof Edgebanding];
  roundedEdgeband?: boolean;
  material?: Material;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Icon
        style={{
          color: 'var(--agent-accent)',
        }}
        icon={<FilledCheckmark />}
      />

      <div className={styles.tooltip}>
        <div>
          {edgeband && (
            <>
              <h4>{t('agent.edgebandConfiguration.title')}</h4>
              <dl style={{ gridTemplateColumns: '1fr 2fr' }}>
                <dt>{t('agent.tooltip.material')}</dt>
                <dd>{material?.name}</dd>
                <dt>
                  {t('agent.edgebandConfiguration.edgebanding.roundedEdgeband')}
                </dt>
                <dd>{roundedEdgeband ? t('agent.yes') : t('agent.no')}</dd>
              </dl>
            </>
          )}
        </div>
        <div className={styles.base} />
      </div>
    </div>
  );
};

const EdgebandSideAiSuggestion = ({
  aiSuggestion,
}: {
  aiSuggestion: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Icon
        style={{
          color: 'var(--primary)',
        }}
        icon={<WarningTriangle />}
      />

      <div className={styles.tooltip}>
        <div>
          {t('agent.quoteFlow.llmFlow.aiSuggestions', {
            suggestions: aiSuggestion,
          })}
        </div>
        <div className={styles.base} />
      </div>
    </div>
  );
};

const EdgebandSideNone = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Icon icon={<CutCircle />} />

      <div className={styles.tooltip}>
        <span>{t('agent.noEdgebanding')}</span>
        <div className={styles.base} />
      </div>
    </div>
  );
};

export const EdgeGroovingSide = ({
  part,
  side,
}: {
  part: PartItem;
  side: keyof PartGrooves;
}) => {
  const groove = part.grooves?.[side];
  const aiSuggestion = part.aiSuggestions?.[`${side}Groove`];

  if (groove?.position === 'none') {
    return <EdgeGroovingSideNone />;
  }

  if (groove) {
    return <EdgeGroovingSideInfo groove={groove} />;
  }

  if (aiSuggestion) {
    return <EdgeGroovingSideAiSuggestion aiSuggestion={aiSuggestion} />;
  }

  return <EdgeGroovingSideNone />;
};

const EdgeGroovingSideInfo = ({
  groove,
}: {
  groove: PartGrooves[keyof PartGrooves];
}) => {
  const { t } = useTranslation();
  const offsetLabel =
    groove?.position === 'side'
      ? t('agent.tooltip.offsetLabelSide')
      : t('agent.tooltip.offsetLabel');

  return (
    <div className={styles.container}>
      <Icon
        style={{
          color: 'var(--agent-accent)',
        }}
        icon={<FilledCheckmark />}
      />

      <div className={styles.tooltip}>
        <div>
          {groove?.position && (
            <>
              <h4>{t('agent.edgeGroove.title')}</h4>
              <dl>
                <dt>{t('agent.edgeGroove.position.label')}</dt>
                <dd>{t(`agent.edgeGroove.position.${groove.position}`)}</dd>
                <dt>{offsetLabel}</dt>
                <dd>{groove.offset}</dd>
                <dt>{t('agent.tooltip.width')}</dt>
                <dd>{groove.width}</dd>
                <dt>{t('agent.tooltip.depth')}</dt>
                <dd>{groove.depth}</dd>
              </dl>
            </>
          )}
        </div>
        <div className={styles.base} />
      </div>
    </div>
  );
};

const EdgeGroovingSideAiSuggestion = ({
  aiSuggestion,
}: {
  aiSuggestion: string;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Icon
        style={{
          color: 'var(--primary)',
        }}
        icon={<WarningTriangle />}
      />

      <div className={styles.tooltip}>
        <div>
          {t('agent.quoteFlow.llmFlow.aiSuggestions', {
            suggestions: aiSuggestion,
          })}
        </div>
        <div className={styles.base} />
      </div>
    </div>
  );
};

const EdgeGroovingSideNone = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Icon icon={<CutCircle />} />

      <div className={styles.tooltip}>
        <span>{t('agent.noEdgeGrooving')}</span>
        <div className={styles.base} />
      </div>
    </div>
  );
};
