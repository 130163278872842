import { Material } from '@cutr/constants/cutlist';
import {
  ALL_CUSTOM_ARTICLES,
  FSC_CLAIM,
  PEFC_CLAIM,
} from '@cutr/constants/material';
import Fuse from 'fuse.js';
import i18n from 'i18next';
import React from 'react';

import {
  getCoreMaterials,
  getEdgebandingMaterials,
  getHPLMaterials,
  getMaterial,
} from './materials';

export const MAX_SEARCH_RESULTS = 200;

type SearchableMaterial = {
  name0: string;
  name1: string;
  name2: string;
  name3: string;
  name4: string;
  name5: string;
  name6: string;
  name7: string;
  name8: string;
  fullName: string;
  length: string;
  width: string;
  thickness: string;
  articleCode: string;
  supplierCode: string;
  category: string;
  fscClaimLabel: string;
  pefcClaimLabel: string;
};

export const getSearchableMaterials = (materials: Material[]) => {
  return materials
    .filter(
      // we don't want custom edgebanding materials in the search.
      // for example, they should not show up for queries such as "bijpassend"
      (material) =>
        !(ALL_CUSTOM_ARTICLES as Material['articleCode'][]).includes(
          material.articleCode
        )
    )
    .map((m) => {
      const uniqueTokens = `${m.name} ${m.variationGroup?.name || ''}`
        .split(' ')
        .filter((token) => token.length)
        .filter((value, index, self) => self.indexOf(value) === index);
      const regex = new RegExp(`([0-9]+mm)|([0-9]+cm)`);
      const filtered = uniqueTokens.filter((token) => {
        return !regex.test(token);
      });

      const obj = filtered.reduce(
        (acc, val, index) => ({ ...acc, [`name${index}`]: val }),
        {}
      );

      const category = formatCategoryForSearch(m.type, m.name);

      const thickness = m.thicknessUM / 1000;
      return {
        ...obj,
        fullName: filtered.join(' '),
        length: String(m.lengthMM),
        width: String(m.widthMM),
        thickness: String(thickness),
        articleCode: m.articleCode,
        supplierCode: m.supplierCode,
        category,
        fscClaimLabel:
          m.fscClaim === FSC_CLAIM.fsc_other ? '' : m.fscClaimLabel || '',
        pefcClaimLabel:
          m.pefcClaim === PEFC_CLAIM.pefc_other ? '' : m.pefcClaimLabel || '',
      } as SearchableMaterial;
    });
};

const formatCategoryForSearch = (type: string, name: string) => {
  if (type === 'unknown') return '';

  // these are the same in Dutch as in English
  if (['hpl', 'osb', 'mdf', 'melamine', 'viroc'].includes(type)) {
    return type;
  }

  const keys = type.split('-');
  if (type === 'concrete-plywood') {
    keys.pop();
    keys.push('formply');
  }
  if (type === 'solid-wood' && name.toLowerCase().includes('bambo')) {
    keys.push('bamboo');
    keys.push(i18n.t('cutlist-form.materialTypes.bamboo'));
  }
  keys.push(i18n.t(`cutlist-form.materialTypes.${type}`));
  return keys;
};

type FuseInstances = {
  hpl: Fuse<SearchableMaterial>;
  core: Fuse<SearchableMaterial>;
  edgeband: Fuse<SearchableMaterial>;
};
const fuseInstances = {} as FuseInstances;

export const getFuseInstance = (type: keyof FuseInstances) =>
  fuseInstances[type];

export const buildFuse = () => {
  const coreConfig: Fuse.IFuseOptions<SearchableMaterial> = {
    includeScore: true,
    keys: [
      'name0',
      'name1',
      'name2',
      'name3',
      'name4',
      'name5',
      'name6',
      'name7',
      'name8',
      'width',
      'length',
      'thickness',
      'articleCode',
      'category',
      'pefcClaimLabel',
      'fscClaimLabel',
    ],
    shouldSort: true,
    useExtendedSearch: true,
  };

  const hplConfig = {
    keys: [
      ...(coreConfig.keys as Fuse.FuseOptionKey<SearchableMaterial>[]),
      { name: 'colorCode', weight: 1 },
    ],
    useExtendedSearch: true,
    includeScore: true,
    shouldSort: true,
  };

  const edgebandingConfig = {
    keys: [
      ...(coreConfig.keys as Fuse.FuseOptionKey<SearchableMaterial>[]),
      { name: 'colorCode', weight: 1 },
    ],
    useExtendedSearch: true,
    includeScore: true,
    shouldSort: true,
  };

  const coreMaterials = getCoreMaterials();
  fuseInstances.core = new Fuse<SearchableMaterial>(
    getSearchableMaterials(coreMaterials),
    coreConfig
  );

  const hplMaterials = getHPLMaterials();
  fuseInstances.hpl = new Fuse(getSearchableMaterials(hplMaterials), hplConfig);

  const edgebandingMaterials = getEdgebandingMaterials();
  fuseInstances.edgeband = new Fuse(
    getSearchableMaterials(edgebandingMaterials),
    edgebandingConfig
  );
};

export const useMaterialSearch = (
  searchTerm: string,
  materialType: 'core' | 'hpl' | 'edgeband' = 'core'
) => {
  const fuse = getFuseInstance(materialType);

  const fuzzySearch = (input: string) => {
    if (!input) {
      return [];
    }

    // replace `20mm` with `20 mm` so that material dimensions are matched properly
    input = input.replace(/(\d)(m)/, (match, g1, g2) => `${g1} ${g2}`);
    const searchTerms = input.split(' ');

    const searchPattern = searchTerms
      .map((val) => {
        return Number.isNaN(parseInt(val)) ? `"${val}"|^${val}` : `=${val}`;
      })
      .join('|');

    return fuse
      .search(searchPattern, { limit: MAX_SEARCH_RESULTS })
      .map(({ item, score }) => {
        return { item, score };
      });
  };

  const searchResults = React.useMemo(
    () => fuzzySearch(searchTerm).map((m) => getMaterial(m.item.articleCode)!),
    [searchTerm, materialType]
  );

  return searchResults;
};
