import { Material, PartItem } from '@cutr/constants/cutlist';
import { ALL_CUSTOM_ARTICLES } from '@cutr/constants/material';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { useFeatureFlag } from '@/api/featureFlags';
import { getEdgebandingMaterials, getMaterial } from '@/api/materials';
import { getFuseInstance } from '@/api/materialSearch';
import { useActiveGroup, useActiveGroupMaterials } from '@/api/materialsGroup';
import { useCutlistState, useUsedBandingMaterials } from '@/api/store';
import { InfoMessage } from '@/blocks/InfoMessage';
import { MaterialRow } from '@/blocks/MaterialRow';
import { Modal } from '@/blocks/Modal';
import i18n from '@/i18n';
import { Button } from '@/primitives/Button';
import { Checkbox } from '@/primitives/Checkbox';
import { Cogwheel, Icon, Search, Star, Trash } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { findMatchingMaterialsWithFallback } from '@/utils/edgeband';

import { formatGroupWithMerging } from './AiSuggestions';
import styles from './EdgebandingModal.module.css';
import {
  EdgebandingProvider,
  Edges,
  EdgeSelectorProvider,
  useEdgebanding,
  useEdgeSelector,
} from './EdgeProcessingContext';
import { EdgesSelector } from './EdgeSelector';
import { useSelectedPartsContext } from './SelectedPartsContext';

export const EditEdgebanding = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const { selectedParts } = useSelectedPartsContext();
  const hasLlmFlowEnabled = useFeatureFlag('invofox-llm-flow');

  if (!hasLlmFlowEnabled) return null;

  const editEdgebandingTooltip =
    selectedParts.length === 0 ? t('agent.atLeastOnePartTooltip') : undefined;

  const afterApply = () => {
    setIsOpen(false);
    toast.success(t('agent.toasts.edgebandingApplied') as string);
  };

  return (
    <>
      <Button
        name="edit-edgebanding"
        disabled={selectedParts.length === 0}
        tooltip={editEdgebandingTooltip}
        onClick={() => setIsOpen(true)}
        variant="secondary"
        icon={<Icon icon={<Cogwheel />} />}
      >
        {t('agent.editEdgebanding')}
      </Button>
      <Modal
        title={t('agent.edgebandConfiguration.title')}
        handleClose={() => setIsOpen(false)}
        isOpen={isOpen}
      >
        <ModalContent afterApply={afterApply} />
      </Modal>
    </>
  );
};

const ModalContent = ({ afterApply }: { afterApply: () => void }) => {
  const { t } = useTranslation();
  const [perEdge, setPerEdge] = React.useState(false);
  const { selectedParts } = useSelectedPartsContext();
  const initialEdgebanding = React.useMemo(() => {
    return parseEdgebanding(selectedParts);
  }, [selectedParts]);

  const initialEdges = React.useMemo(() => {
    return parseEdges(selectedParts);
  }, [selectedParts]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPerEdge(e.target.checked);
  };

  return (
    <EdgeSelectorProvider initialEdgesToApply={initialEdges}>
      <EdgebandingProvider initialState={initialEdgebanding}>
        <div className="stack">
          <AiSuggestions />
          <h3>{t('agent.editEdgebanding')}</h3>
          <Checkbox isSwitch defaultChecked={perEdge} onChange={onChange}>
            {t('agent.edgebandConfiguration.differentForAll')}
          </Checkbox>
          {perEdge && <MultipleMaterialConfig onApply={afterApply} />}
          {!perEdge && <SingleMaterialConfig onApply={afterApply} />}
        </div>
      </EdgebandingProvider>
    </EdgeSelectorProvider>
  );
};

const SingleMaterialConfig = ({ onApply }: { onApply: () => void }) => {
  const { t } = useTranslation();
  const { roundedEdgeband, edgeband, setRoundedEdgeband, clearState } =
    useEdgebanding();
  const { edgesToApply, setEdgesToApply } = useEdgeSelector();
  const { setPart } = useCutlistState();
  const { selectedParts } = useSelectedPartsContext();
  const materials = useEdgebandingModalMaterials(false);
  const handleRoundedEdgebandChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRoundedEdgeband(event.target.checked, 'all');
  };
  const { clear: clearEdgeSelector } = useEdgeSelector();

  const resetEdges = (val: boolean) => {
    setEdgesToApply({
      length1: val ? 'on' : 'off',
      length2: val ? 'on' : 'off',
      width1: val ? 'on' : 'off',
      width2: val ? 'on' : 'off',
    });
  };

  const handleApplyEdgeProcessing = (e: React.FormEvent) => {
    e.preventDefault();
    const partsToUpdate: PartItem[] = selectedParts;

    partsToUpdate.forEach((part) => {
      const newPart = { ...part };

      const newEdgebanding: PartItem['edgebanding'] = {
        length1: null,
        length2: null,
        width1: null,
        width2: null,
      };

      const newRoundedEdgebanding: PartItem['roundedEdgeband'] = {
        length1: false,
        length2: false,
        width1: false,
        width2: false,
      };

      // edgeband input doesn't match any material
      if (!materials.find((m) => m?.edge === 'all')) {
        newPart.edgebanding = newEdgebanding;
        newPart.roundedEdgeband = newRoundedEdgebanding;
        newPart.aiMeta = {
          length1AppliedEdgeband: true,
          length2AppliedEdgeband: true,
          width1AppliedEdgeband: true,
          width2AppliedEdgeband: true,
        };
        setPart(newPart);
        return;
      }

      ['length1', 'length2', 'width1', 'width2'].forEach((v) => {
        const edge = v as keyof Omit<Edges, 'all'>;
        if (edgesToApply[edge] === 'off') return;

        newPart.aiMeta = {
          ...newPart.aiMeta,
          [`${edge}AppliedEdgeband`]: true,
        };

        newEdgebanding[edge] = edgeband;
        newRoundedEdgebanding[edge] = roundedEdgeband;
      });

      newPart.edgebanding = newEdgebanding;
      newPart.roundedEdgeband = newRoundedEdgebanding;

      setPart(newPart);
    });
    clearState();
    clearEdgeSelector();
    onApply();
  };

  const hasMixedEdges = Object.values(edgesToApply).some(
    (edgeState) => edgeState === 'mixed'
  );

  const hasMixedMaterial = materials.length === 0 && edgeband?.length > 0;
  const hasMixedState = hasMixedEdges || hasMixedMaterial;

  const getTooltip = () => {
    if (hasMixedEdges) return t('agent.edgebandConfiguration.mixedEdges');
    if (hasMixedMaterial) return t('agent.edgebandConfiguration.mixedMaterial');
    return undefined;
  };

  return (
    <>
      <Autocomplete side="all" />
      <div className={styles.inputContainer}>
        <Checkbox
          checked={roundedEdgeband}
          onChange={handleRoundedEdgebandChange}
          isSwitch
        >
          {t('agent.edgebandConfiguration.edgebanding.roundedEdgeband')}
        </Checkbox>
      </div>

      <div>
        <div className="flexAlign">
          <h4>{t('agent.preview.toApplyTo')}</h4>
          <Button variant="secondaryPill" onClick={() => resetEdges(true)}>
            {t('agent.preview.selectAll')}
          </Button>
          <Button variant="secondaryPill" onClick={() => resetEdges(false)}>
            {t('agent.preview.selectNone')}
          </Button>
        </div>
        <div style={{ padding: 'var(--space-l)' }}>
          <EdgesSelector>
            <strong>{t('agent.preview.willApplyTo')}</strong>
            <br />
            {t('agent.preview.selectedPartsNr', {
              count: selectedParts.length,
            })}
          </EdgesSelector>
        </div>
        <Materials perEdge={false} />
        <Button
          onClick={handleApplyEdgeProcessing}
          disabled={hasMixedState}
          tooltip={getTooltip()}
        >
          {t('agent.applyEdgeProcessing')}
        </Button>
      </div>
    </>
  );
};

const MultipleMaterialConfig = ({ onApply }: { onApply: () => void }) => {
  const { t } = useTranslation();
  const { selectedParts } = useSelectedPartsContext();
  const { setPart } = useCutlistState();
  const {
    roundedEdgebandPerEdge,
    edgebandPerEdge,
    setRoundedEdgeband,
    clearState,
  } = useEdgebanding();
  const materials = useEdgebandingModalMaterials(true);
  const handleRoundedEdgebandChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    label: keyof Edges
  ) => {
    setRoundedEdgeband(event.target.checked, label);
  };
  const { clear: clearEdgeSelector } = useEdgeSelector();

  const handleApplyEdgeProcessing = (e: React.FormEvent) => {
    e.preventDefault();
    const partsToUpdate: PartItem[] = selectedParts;

    partsToUpdate.forEach((part) => {
      const newPart = { ...part };

      const newEdgebanding: PartItem['edgebanding'] = {
        length1:
          materials.find((m) => m?.edge === 'length1')?.articleCode ?? null,
        length2:
          materials.find((m) => m?.edge === 'length2')?.articleCode ?? null,
        width1:
          materials.find((m) => m?.edge === 'width1')?.articleCode ?? null,
        width2:
          materials.find((m) => m?.edge === 'width2')?.articleCode ?? null,
      };

      const newRoundedEdgebanding: PartItem['roundedEdgeband'] = {
        length1: roundedEdgebandPerEdge.length1,
        length2: roundedEdgebandPerEdge.length2,
        width1: roundedEdgebandPerEdge.width1,
        width2: roundedEdgebandPerEdge.width2,
      };

      newPart.edgebanding = newEdgebanding;
      newPart.roundedEdgeband = newRoundedEdgebanding;
      newPart.aiMeta = {
        length1AppliedEdgeband: true,
        length2AppliedEdgeband: true,
        width1AppliedEdgeband: true,
        width2AppliedEdgeband: true,
      };

      setPart(newPart);
    });
    clearState();
    clearEdgeSelector();
    onApply();
  };
  const nrOfEdgebandingSides = [
    'length1',
    'length2',
    'width1',
    'width2',
  ].filter((edge) => edgebandPerEdge[edge as keyof Edges]?.length > 0).length;

  const hasMixedState = nrOfEdgebandingSides !== materials.length;

  const getTooltip = () => {
    if (hasMixedState) return t('agent.edgebandConfiguration.mixedMaterial');
    return undefined;
  };

  return (
    <div className={styles.grid}>
      {(['length1', 'length2', 'width1', 'width2'] as (keyof Edges)[]).map(
        (label) => (
          <div key={label}>
            <label>
              {t(`cutlist-form.field.edgebanding.sides.${labelToKey(label)}`)}
            </label>
            <Autocomplete side={label} />
            <div>
              <Checkbox
                className={cn(styles.light, 'pad')}
                checked={roundedEdgebandPerEdge[label]}
                onChange={(e) => handleRoundedEdgebandChange(e, label)}
                disabled={
                  materials.filter((m) => m?.edge === label).length === 0
                }
                isSwitch
              >
                {t('agent.edgebandConfiguration.edgebanding.roundedEdgeband')}
              </Checkbox>
            </div>
          </div>
        )
      )}

      <Materials perEdge={true} />
      <Button
        onClick={handleApplyEdgeProcessing}
        disabled={hasMixedState}
        tooltip={getTooltip()}
      >
        {t('agent.applyEdgeProcessing')}
      </Button>
    </div>
  );
};

const AiSuggestions = () => {
  const { t } = useTranslation();
  const { selectedParts } = useSelectedPartsContext();
  const hasLlmFlowEnabled = useFeatureFlag('invofox-llm-flow');

  if (!hasLlmFlowEnabled) return <p></p>;
  const suggestions = parseLlmSuggestions(selectedParts);

  if (Object.keys(suggestions).length === 0) return <p></p>;

  if (
    Object.keys(suggestions).length === 1 &&
    Object.keys(suggestions)[0] === ''
  )
    return <p></p>;

  if (Object.keys(suggestions).length > 1)
    return (
      <InfoMessage message={t('agent.quoteFlow.llmFlow.mixed')} variant="ai" />
    );

  const formatSuggestions = (suggestions: Record<string, PartItem[]>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, value] = Object.entries(suggestions)[0];
    const part = value[0];
    const edgeband = {
      l1: part.aiSuggestions.length1Edgeband,
      l2: part.aiSuggestions.length2Edgeband,
      w1: part.aiSuggestions.width1Edgeband,
      w2: part.aiSuggestions.width2Edgeband,
    };

    return formatGroupWithMerging(
      edgeband,
      t('cutlist-form.field.aiSuggestions.edgebands'),
      t
    );
  };

  return (
    <InfoMessage
      message={t('agent.quoteFlow.llmFlow.aiSuggestions', {
        suggestions: formatSuggestions(suggestions),
      })}
      variant="ai"
    />
  );
};

const parseLlmSuggestions = (selectedParts: PartItem[]) => {
  return selectedParts
    .map((part) => {
      const edgebandSuggestions = [
        part.aiSuggestions.length1Edgeband,
        part.aiSuggestions.length2Edgeband,
        part.aiSuggestions.width1Edgeband,
        part.aiSuggestions.width2Edgeband,
      ]
        .filter(Boolean)
        .join('|');

      return { part, edgebandSuggestions };
    })
    .reduce((acc, curr) => {
      const suggestions = curr.edgebandSuggestions;
      if (!acc[suggestions]) {
        acc[suggestions] = [];
      }
      acc[suggestions].push(curr.part);
      return acc;
    }, {} as Record<string, PartItem[]>);
};

const Materials = ({ perEdge }: { perEdge: boolean }) => {
  const { setEdgeband } = useEdgebanding();
  const { t } = useTranslation();
  const materials = useEdgebandingModalMaterials(perEdge);

  if (!materials.length) return null;

  const removeMaterial = (edge: keyof Edges) => {
    setEdgeband('', edge);
  };
  return (
    <div className={styles.materialContainer}>
      {materials.map((m) => {
        if (!m) return null;
        return (
          <div key={m.edge} className="stack">
            <strong>
              {t(`cutlist-form.field.edgebanding.sides.${labelToKey(m.edge)}`)}
            </strong>
            <div className={'opposites'}>
              <MaterialRow item={m} />

              <Button
                icon={<Icon icon={<Trash />} size={3} />}
                variant="icon"
                className="delete"
                onClick={() => removeMaterial(m.edge as keyof Edges)}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

const manualOptions = ALL_CUSTOM_ARTICLES as string[];
type AutocompleteProps = {
  side: keyof Edges;
};
const Autocomplete = ({ side }: AutocompleteProps) => {
  const { edgebandPerEdge, setEdgeband } = useEdgebanding();
  const val = edgebandPerEdge[side] || '';
  const setVal = (val: Material['articleCode']) => setEdgeband(val, side);
  const [focus, setFocus] = React.useState(false);
  const { t } = useTranslation();
  const activeGroup = useActiveGroup();
  const currentEdgeband = getMaterial(activeGroup?.edgeband);

  const ebMaterials = getEdgebandingMaterials();
  const sheetMaterials = useActiveGroupMaterials();
  const recentMaterials = useUsedBandingMaterials();
  const matchingMaterials =
    val?.length ?? 0 > 3
      ? []
      : findMatchingMaterialsWithFallback(sheetMaterials, ebMaterials);

  const matchingList = matchingMaterials.map((m) => m.articleCode);
  const filterMaterialsArticleCode = (m?: Material) => {
    if (!m) return false;
    return (
      !matchingList.includes(m.articleCode) &&
      !manualOptions.includes(m.articleCode) &&
      activeGroup?.edgeband !== m.articleCode &&
      (!val || String(m.articleCode).startsWith(val))
    );
  };

  const filteredMaterialsText = (input: string) => {
    const fuse = getFuseInstance('edgeband');
    const searchTerms = input.split(' ');
    const search = searchTerms.reduce((acc, val) => {
      return acc + (Number.isNaN(parseInt(val)) ? `^${val}|` : `=${val}|`);
    }, '');

    return fuse.search(search).map(({ item, score }) => {
      return { item, score };
    });
  };

  const defaultMaterials = recentMaterials.filter(filterMaterialsArticleCode);

  let materials = val
    ? ebMaterials.filter(filterMaterialsArticleCode).splice(0, 5)
    : defaultMaterials;

  if (val) {
    const filteredMaterials = filteredMaterialsText(val);
    const mappedMaterials = ebMaterials
      .filter((m) =>
        filteredMaterials.some((f) => f.item.articleCode === m.articleCode)
      )
      .sort((a, b) => {
        const item1 = filteredMaterials.find(
          (m) => m.item.articleCode === a.articleCode
        );
        const item2 = filteredMaterials.find(
          (m) => m.item.articleCode === b.articleCode
        );
        const score1 = item1 ? Number(item1.score) : 0;
        const score2 = item2 ? Number(item2.score) : 0;
        return score1 - score2;
      });
    materials = val ? mappedMaterials?.splice(0, 10) : recentMaterials;
  }

  const expanded =
    focus && Boolean(materials.length || matchingMaterials.length);

  const handleMaterialSelection = (code: Material['articleCode']): void => {
    setVal(code);
    setFocus(false);
  };

  return (
    <div
      style={{
        position: 'relative',
      }}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          setFocus(false);
        }

        if (e.key === 'Enter') {
          if (materials.length === 1 && materials[0]) {
            handleMaterialSelection(materials[0].articleCode);
            return;
          }

          const el = e.currentTarget.parentElement?.querySelector(
            // TODO: ugly coupling
            '.materialRow[aria-selected="true"]'
          );
          if (!el) return;

          const code = el.getAttribute('data-id');
          if (!code) return;

          el.setAttribute('aria-selected', 'false');
          handleMaterialSelection(code);
        }

        let els: HTMLElement[] = [];
        let found = -1;
        if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
          setFocus(true);
          els = [
            ...(e.currentTarget.parentElement?.querySelectorAll(
              // TODO: ugly coupling
              '.materialRow'
            ) || []),
          ] as HTMLElement[];

          for (const el of els) {
            if (el.getAttribute('aria-selected') === 'true') {
              el.setAttribute('aria-selected', 'false');
              found = els.findIndex((ell) => ell === el);
              continue;
            }
          }
        }
        if (e.key === 'ArrowDown') {
          const targetEl = els[found + 1] ? els[found + 1] : els[0];
          targetEl?.setAttribute('aria-selected', 'true');
        }
        if (e.key === 'ArrowUp') {
          const targetEl = els[found - 1]
            ? els[found - 1]
            : els[els.length - 1];
          targetEl?.setAttribute('aria-selected', 'true');
        }
      }}
    >
      <Input
        icon={<Icon icon={<Search />} />}
        placeholder={t('cutlist-form.field.edgebanding.placeholder')}
        value={val || ''}
        aria-autocomplete="both"
        aria-controls={`autocomplete-${side}`}
        aria-expanded={expanded}
        aria-haspopup="listbox"
        aria-label={t('cutlist-form.field.edgebanding.label')}
        role="combobox"
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
          setVal(e.target.value);
          setFocus(true);
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setTimeout(() => setFocus(false), 150);
        }}
      />
      {expanded && (
        <div
          className={styles.dropdown}
          role="listbox"
          id={`autocomplete-${side}`}
        >
          {currentEdgeband !== undefined && (
            <>
              <h4 className={styles.dropdownTitle}>
                {t('cutlist-form.matchingEdgeband.default')}
              </h4>
              <MaterialRow
                role="option"
                tabIndex={-1}
                aria-selected="false"
                className="materialRow"
                item={currentEdgeband}
                key={currentEdgeband.id}
                onSelected={() =>
                  handleMaterialSelection(currentEdgeband.articleCode)
                }
              />
            </>
          )}
          {Boolean(matchingMaterials.length) && (
            <>
              <h4
                className={styles.dropdownTitle}
                style={{ color: 'var(--calendulaGold)' }}
              >
                <Icon icon={<Star />} size={2} />
                <span>{t('cutlist-form.matchingEdgeband.title')}</span>
              </h4>

              {matchingMaterials.map((m) => (
                <MaterialRow
                  role="option"
                  tabIndex={-1}
                  aria-selected="false"
                  className="materialRow"
                  item={m}
                  key={m.id}
                  onSelected={() => handleMaterialSelection(m.articleCode)}
                />
              ))}
              {Boolean(materials.length) && (
                <h4 className={styles.dropdownTitle}>
                  {t('cutlist-form.matchingEdgeband.searchResults')}
                </h4>
              )}
            </>
          )}
          {materials.map((m) => (
            <MaterialRow
              role="option"
              tabIndex={-1}
              aria-selected="false"
              className="materialRow"
              item={m}
              key={m.id}
              onSelected={() => handleMaterialSelection(m.articleCode)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const labelToKey = (label: keyof Edges) =>
  label.replace('length', 'l').replace('width', 'w') as keyof Edges;

const useEdgebandingModalMaterials = (perEdge: boolean) => {
  const { edgeband, edgebandPerEdge } = useEdgebanding();
  const keys = ['length1', 'length2', 'width1', 'width2'] as const;

  const materials = React.useMemo(() => {
    if (!perEdge) {
      const material = getEdgebandingMaterials().find(
        ({ articleCode }) => articleCode === edgeband
      );

      if (!material) return [];

      return [{ ...material, edge: 'all' as keyof Edges }];
    }

    const articleCodes = keys.map((key) => edgebandPerEdge[key]);
    return getEdgebandingMaterials()
      .filter((material) => {
        const articleCode = material.articleCode;
        if (articleCodes.includes(articleCode)) return true;
      })
      .flatMap((material) => {
        const articleCode = material.articleCode;

        const materialEdges = keys.filter(
          (key) => edgebandPerEdge[key] === articleCode
        );

        return materialEdges.map((edge) => ({
          ...material,
          edge,
        }));
      });
  }, [JSON.stringify(edgebandPerEdge), edgeband, perEdge]);

  return materials;
};

const parseEdgebanding = (selectedParts: PartItem[]) => {
  const mixed = i18n.t('agent.mixed');
  const edges = parseEdges(selectedParts);

  const getValuesPerEdge = (edge: keyof Omit<Edges, 'all'>) => {
    const set = new Set<Material['articleCode'] | undefined>();
    selectedParts.forEach((part) => {
      const value = part.edgebanding?.[edge] ?? undefined;

      set.add(value);
    });

    if (set.size === 0) return '';
    if (set.size === 1) return set.values().next().value;
    return mixed;
  };

  const getEdgebandingForAllEdges = () => {
    const map = new Map<
      keyof Edges,
      Set<Material['articleCode'] | undefined>
    >();

    Object.entries(edges).forEach(([edge]) => {
      const set = new Set<Material['articleCode'] | undefined>();
      const side = edge as keyof Omit<Edges, 'all'>;

      selectedParts.forEach((part) => {
        const value = part.edgebanding?.[side] ?? undefined;
        if (value) {
          set.add(value);
        }
      });

      map.set(side, set);
    });
    const dimensions = new Set(
      Array.from(map.entries()).flatMap(([, value]) => Array.from(value))
    );

    if (dimensions.size === 0) return '';
    if (dimensions.size === 1) return dimensions.values().next().value;

    return mixed;
  };

  const getRoundedEdgebandValuesPerEdge = (edge: keyof Omit<Edges, 'all'>) => {
    const set = new Set<boolean>();
    selectedParts.forEach((part) => {
      const value = part.roundedEdgeband?.[edge] ?? false;
      set.add(value);
    });

    if (set.size === 0) return false;
    if (set.size === 1) return set.values().next().value;
    return 'mixed';
  };

  const getRoundedEdgebandForAllEdges = () => {
    const map = new Map<keyof Edges, Set<boolean>>();

    Object.entries(edges)
      .filter(([, edgeState]) => edgeState === 'on')
      .forEach(([edge]) => {
        const set = new Set<boolean>();
        const side = edge as keyof Omit<Edges, 'all'>;

        selectedParts.forEach((part) => {
          const value = part.roundedEdgeband[side];

          set.add(value ?? false);
        });

        map.set(side, set);
      });
    const dimensions = new Set(
      Array.from(map.entries()).flatMap(([, value]) => Array.from(value))
    );

    if (dimensions.size === 0) return false;
    if (dimensions.size === 1) return dimensions.values().next().value;

    return false;
  };

  return {
    length1: getValuesPerEdge('length1'),
    length2: getValuesPerEdge('length2'),
    width1: getValuesPerEdge('width1'),
    width2: getValuesPerEdge('width2'),
    all: getEdgebandingForAllEdges(),
    roundedEdgeband: {
      length1: getRoundedEdgebandValuesPerEdge('length1'),
      length2: getRoundedEdgebandValuesPerEdge('length2'),
      width1: getRoundedEdgebandValuesPerEdge('width1'),
      width2: getRoundedEdgebandValuesPerEdge('width2'),
      all: getRoundedEdgebandForAllEdges(),
    },
  };
};

const parseEdges = (selectedParts: PartItem[]) => {
  const hasEdgebandOnEdge = (edge: keyof Omit<Edges, 'all'>) => {
    if (selectedParts.every((part) => isEdgebandOnEdge(part, edge))) {
      return 'on' as const;
    }

    if (selectedParts.some((part) => isEdgebandOnEdge(part, edge))) {
      return 'mixed' as const;
    }

    return 'off' as const;
  };

  return {
    length1: hasEdgebandOnEdge('length1'),
    length2: hasEdgebandOnEdge('length2'),
    width1: hasEdgebandOnEdge('width1'),
    width2: hasEdgebandOnEdge('width2'),
  };
};

const isEdgebandOnEdge = (part: PartItem, edge: keyof Omit<Edges, 'all'>) => {
  const edgeband = part.edgebanding?.[edge];
  return Boolean(edgeband);
};
