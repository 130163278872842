import { Material, PartItem } from '@cutr/constants/cutlist';
import { ALL_CUSTOM_ARTICLES } from '@cutr/constants/material';
import React from 'react';
import { createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreApi } from 'zustand';
import { createStore, useStore as useZustandStore } from 'zustand';

import { getEdgebandingMaterials, getMaterial } from '@/api/materials';
import { getFuseInstance } from '@/api/materialSearch';
import { useActiveGroup, useActiveGroupMaterials } from '@/api/materialsGroup';
import { useUsedBandingMaterials } from '@/api/store';
import { EdgesPreview } from '@/blocks/EdgesPreview';
import { MaterialRow } from '@/blocks/MaterialRow';
import { Button } from '@/primitives/Button';
import { Checkbox } from '@/primitives/Checkbox';
import { Icon, Search, SelectedEdges, Star, Trash } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { useCurrentFeatures, useCurrentSource } from '@/theme';
import { findMatchingMaterialsWithFallback } from '@/utils/edgeband';
import { copyToClipboard, isTruthy } from '@/utils/misc';

import styles from './Edgebanding.module.css';

export const EDGEBANDING_SIDES = ['l1', 'l2', 'w1', 'w2'];
export type EdgebandingSide = 'l1' | 'l2' | 'w1' | 'w2';

const useStore = () => {
  const storeApi = useContext(Context);
  if (!storeApi) throw new Error('Missing Context.Provider in the tree.');
  return useZustandStore(storeApi);
};

interface EdgebandingModalState {
  edges: {
    l1?: Material['articleCode'];
    l2?: Material['articleCode'];
    w1?: Material['articleCode'];
    w2?: Material['articleCode'];
    all?: Material['articleCode'];
  };
  matchAll: boolean;
  setMatchAll: (match: boolean) => void;
  setEdge: (
    key: keyof EdgebandingModalState['edges'],
    id: Material['articleCode'] | null
  ) => void;
  reset: () => void;
  setEdges: (state: InitialEdges) => void;
}

const Context = createContext<StoreApi<EdgebandingModalState> | null>(null);

const INITIAL_STATE = { matchAll: false } as InitialEdges;
export const useEdgebandingState = ({
  matchAll,
  ...edges
}: InitialEdges = INITIAL_STATE) =>
  createStore<EdgebandingModalState>((set) => ({
    edges,
    matchAll: matchAll,
    reset: () => set(() => ({ edges: {} })),
    setEdges: ({ matchAll, ...edges }) => set(() => ({ matchAll, edges })),
    setMatchAll: (matchAll) => set(() => ({ matchAll })),
    setEdge: (key, id) =>
      set((state) => {
        const edges = { ...state.edges, [key]: id };
        return { edges };
      }),
  }));

export const useEdgebanding = () => {
  const { edges, matchAll } = useStore();

  const getByCode = (materialCode?: Material['articleCode']) =>
    getEdgebandingMaterials().find(
      ({ articleCode }) => materialCode === articleCode
    );

  if (matchAll) {
    const material = getByCode(edges.all);
    return {
      matchAll: true,
      l1: material,
      l2: material,
      w1: material,
      w2: material,
    };
  }

  const { l1, l2, w1, w2 } = edges;
  return {
    matchAll: false,
    l1: getByCode(l1),
    l2: getByCode(l2),
    w1: getByCode(w1),
    w2: getByCode(w2),
  };
};

export type UseEdgebanding = ReturnType<typeof useEdgebanding>;

export const EdgebandingContextProvider = ({
  children,
  initialEdges,
}: {
  children: React.ReactNode;
  initialEdges?: InitialEdges;
}) => {
  const storeRef = React.useRef<StoreApi<EdgebandingModalState>>();
  if (!storeRef.current) {
    storeRef.current = useEdgebandingState(initialEdges as InitialEdges);
  }

  return (
    <Context.Provider value={storeRef.current}>
      {children}
      <Syncer initialEdges={initialEdges as InitialEdges} />
    </Context.Provider>
  );
};

// it syncs the main app store with the context when edgebanding changes
const Syncer = ({ initialEdges }: { initialEdges: InitialEdges }) => {
  const { setEdges } = useStore();
  React.useEffect(() => {
    setEdges(initialEdges);
  }, [initialEdges]);
  return null;
};

type EdgebandingModalProps = {
  onSave: (edges: EdgebandingEdges) => void;
  part: {
    label: string;
    size: [number, number];
    quantity: number;
  };
};

const EdgebandingInterface = ({ onSave, part }: EdgebandingModalProps) => {
  const { matchAll, setMatchAll } = useStore();
  const edges = useEdgebanding();
  const { t } = useTranslation();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMatchAll(e.target.checked);
  };

  return (
    <div>
      <EdgesPreview
        label={part.label}
        size={part.size}
        quantity={part.quantity}
        edges={edges}
      />
      <div className="stack">
        <div className="fitWidth">
          <Checkbox isSwitch defaultChecked={matchAll} onChange={onChange}>
            <strong>
              {t('cutlist-form.field.edgebanding.matchAllToggle')}
            </strong>
          </Checkbox>
        </div>
        {matchAll && <Autocomplete side="all" />}
        {!matchAll && <EdgebandingGrid />}

        <Materials />

        <Button onClick={() => onSave(edges)}>{t('common.cta.save')}</Button>
      </div>
    </div>
  );
};

const EdgebandingGrid = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.grid}>
      {EDGEBANDING_SIDES.map((label) => (
        <div key={label}>
          <label>{t(`cutlist-form.field.edgebanding.sides.${label}`)}</label>
          <Autocomplete side={label as keyof EdgebandingModalState['edges']} />
        </div>
      ))}
    </div>
  );
};

const manualOptions = ALL_CUSTOM_ARTICLES as string[];
const Autocomplete = ({
  side,
}: {
  side: keyof EdgebandingModalState['edges'];
}) => {
  const { edges, setEdge } = useStore();
  const val = edges[side] || '';
  const setVal = (val: Material['articleCode']) => setEdge(side, val);
  const [focus, setFocus] = React.useState(false);
  const { t } = useTranslation();
  const source = useCurrentSource();
  const activeGroup = useActiveGroup();
  const currentEdgeband = getMaterial(activeGroup?.edgeband);

  const ebMaterials = getEdgebandingMaterials();
  const sheetMaterials = useActiveGroupMaterials();
  const recentMaterials = useUsedBandingMaterials();
  const matchingMaterials =
    val.length > 3
      ? []
      : findMatchingMaterialsWithFallback(sheetMaterials, ebMaterials);

  const matchingList = matchingMaterials.map((m) => m.articleCode);
  const filterMaterialsArticleCode = (m: Material) => {
    return (
      !matchingList.includes(m.articleCode) &&
      !manualOptions.includes(m.articleCode) &&
      activeGroup?.edgeband !== m.articleCode &&
      (!val || String(m.articleCode).startsWith(val))
    );
  };

  const filteredMaterialsText = (input: string) => {
    const fuse = getFuseInstance('edgeband');
    const searchTerms = input.split(' ');
    const search = searchTerms.reduce((acc, val) => {
      return acc + (Number.isNaN(parseInt(val)) ? `^${val}|` : `=${val}|`);
    }, '');

    return fuse.search(search).map(({ item, score }) => {
      return { item, score };
    });
  };

  const defaultMaterials = recentMaterials.filter(filterMaterialsArticleCode);

  let materials = val
    ? ebMaterials.filter(filterMaterialsArticleCode).splice(0, 5)
    : defaultMaterials;

  if (val) {
    const filteredMaterials = filteredMaterialsText(val);
    const mappedMaterials = ebMaterials
      .filter((m) =>
        filteredMaterials.some((f) => f.item.articleCode === m.articleCode)
      )
      .sort((a, b) => {
        const item1 = filteredMaterials.find(
          (m) => m.item.articleCode === a.articleCode
        );
        const item2 = filteredMaterials.find(
          (m) => m.item.articleCode === b.articleCode
        );
        const score1 = item1 ? Number(item1.score) : 0;
        const score2 = item2 ? Number(item2.score) : 0;
        return score1 - score2;
      });
    materials = val ? mappedMaterials?.splice(0, 10) : recentMaterials;
  }

  const expanded =
    focus && Boolean(materials.length || matchingMaterials.length);

  const handleMaterialSelection = (
    code: Material['articleCode'],
    side?: string
  ): void => {
    setVal(code);
    setFocus(false);
    copyToClipboard(code);

    const isMatching = matchingMaterials.find((m) => m.articleCode === code);
    const isManual = manualOptions.includes(code);

    window.analytics.track('Edgeband modal edit', {
      isManual,
      isMatching,
      found: matchingMaterials.length,
      matchingCode: code,
      ticker: source,
      side: side,
    });
  };

  return (
    <div
      className={styles.autocomplete}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          setFocus(false);
        }

        if (e.key === 'Enter') {
          if (materials.length === 1 && materials[0]) {
            handleMaterialSelection(materials[0].articleCode, side);
            return;
          }

          const el = e.currentTarget.parentElement?.querySelector(
            // TODO: ugly coupling
            '.materialRow[aria-selected="true"]'
          );
          if (!el) return;

          const code = el.getAttribute('data-id');
          if (!code) return;

          el.setAttribute('aria-selected', 'false');
          handleMaterialSelection(code, side);
        }

        let els: HTMLElement[] = [];
        let found = -1;
        if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
          setFocus(true);
          els = [
            ...(e.currentTarget.parentElement?.querySelectorAll(
              // TODO: ugly coupling
              '.materialRow'
            ) || []),
          ] as HTMLElement[];

          for (const el of els) {
            if (el.getAttribute('aria-selected') === 'true') {
              el.setAttribute('aria-selected', 'false');
              found = els.findIndex((ell) => ell === el);
              continue;
            }
          }
        }
        if (e.key === 'ArrowDown') {
          const targetEl = els[found + 1] ? els[found + 1] : els[0];
          targetEl?.setAttribute('aria-selected', 'true');
        }
        if (e.key === 'ArrowUp') {
          const targetEl = els[found - 1]
            ? els[found - 1]
            : els[els.length - 1];
          targetEl?.setAttribute('aria-selected', 'true');
        }
      }}
    >
      <Input
        icon={<Icon icon={<Search />} />}
        placeholder={t('cutlist-form.field.edgebanding.placeholder')}
        value={val}
        aria-autocomplete="both"
        aria-controls={`autocomplete-${side}`}
        aria-expanded={expanded}
        aria-haspopup="listbox"
        aria-label={t('cutlist-form.field.edgebanding.label')}
        role="combobox"
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
          setVal(e.target.value);
          setFocus(true);
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setTimeout(() => setFocus(false), 150);
        }}
      />
      {expanded && (
        <div
          className={styles.dropdown}
          role="listbox"
          id={`autocomplete-${side}`}
        >
          {currentEdgeband !== undefined && (
            <>
              <h4 className={styles.dropdownTitle}>
                {t('cutlist-form.matchingEdgeband.default')}
              </h4>
              <MaterialRow
                role="option"
                tabIndex={-1}
                aria-selected="false"
                className="materialRow"
                item={currentEdgeband}
                key={currentEdgeband.id}
                onSelected={() =>
                  handleMaterialSelection(currentEdgeband.articleCode, side)
                }
              />
            </>
          )}
          {Boolean(matchingMaterials.length) && (
            <>
              <h4
                className={styles.dropdownTitle}
                style={{ color: 'var(--calendulaGold)' }}
              >
                <Icon icon={<Star />} size={2} />
                <span>{t('cutlist-form.matchingEdgeband.title')}</span>
              </h4>

              {matchingMaterials.map((m) => (
                <MaterialRow
                  role="option"
                  tabIndex={-1}
                  aria-selected="false"
                  className="materialRow"
                  item={m}
                  key={m.id}
                  onSelected={() =>
                    handleMaterialSelection(m.articleCode, side)
                  }
                />
              ))}
              {Boolean(materials.length) && (
                <h4 className={styles.dropdownTitle}>
                  {t('cutlist-form.matchingEdgeband.searchResults')}
                </h4>
              )}
            </>
          )}
          {materials.map((m) => (
            <MaterialRow
              role="option"
              tabIndex={-1}
              aria-selected="false"
              className="materialRow"
              item={m}
              key={m.id}
              onSelected={() => handleMaterialSelection(m.articleCode, side)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export const Materials = () => {
  const { edges, matchAll, setEdge } = useStore();
  const { t } = useTranslation();
  const list = ['all', 'l1', 'l2', 'w1', 'w2'] as const;

  const materials = list
    .map((key) => {
      if (!matchAll && key === list[0]) return;
      if (matchAll && key !== list[0]) return;
      const material = getEdgebandingMaterials().find(
        ({ articleCode }) => articleCode === edges[key]
      );

      if (!material) return;

      return { ...material, side: key.toUpperCase() };
    })
    .filter(Boolean);

  if (!materials.length) return null;

  return (
    <div className={styles.materialContainer}>
      {materials.map((m) => {
        if (!m) return null;
        return (
          <div key={m.side} className="stack">
            <strong>
              {t(
                `cutlist-form.field.edgebanding.sides.${m.side.toLowerCase()}`
              )}
            </strong>
            <div className={'opposites'}>
              <MaterialRow item={m} />

              <Button
                icon={<Icon icon={<Trash />} size={3} />}
                variant="icon"
                className="delete"
                onClick={() =>
                  setEdge(
                    m.side.toLowerCase() as keyof EdgebandingModalState['edges'],
                    null
                  )
                }
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

// TODO: should be a button
export const EdgebandingIcon = ({ onClick }: { onClick: () => void }) => {
  const edges = useEdgebanding();
  const { t } = useTranslation();
  const { edgebandingUI } = useCurrentFeatures();

  const sides = [edges.l1, edges.l2, edges.w1, edges.w2]
    .filter(isTruthy)
    .map((e) => e.articleCode);
  const hasEdges = Boolean(sides.length);
  const codes = sides.filter((v, i, a) => a.indexOf(v) === i);
  const multipleEdgesLabel = (function () {
    if (!hasEdges) return t('cutlist-form.field.edgebanding.status.notSet');
    if (codes.length === 1)
      return edgebandingUI
        ? t('cutlist-form.field.edgebanding.status.custom')
        : codes[0];

    return t('cutlist-form.field.edgebanding.status.mixed');
  })();

  return (
    <Button onClick={onClick} variant="icon" className="outline full">
      <div className="flexAlign gap-xs">
        <Icon
          size={1.5}
          icon={
            <SelectedEdges
              borders={[
                Boolean(edges.l2),
                Boolean(edges.w2),
                Boolean(edges.l1),
                Boolean(edges.w1),
              ]}
            />
          }
        />
        {edges.matchAll
          ? edges.l1?.articleCode ||
            t('cutlist-form.field.edgebanding.status.notSet')
          : multipleEdgesLabel}
      </div>
    </Button>
  );
};

export type EdgebandingEdges = ReturnType<typeof useEdgebanding>;
export default EdgebandingInterface;

export function parseEdgeBanding(edges: PartItem['edgebanding']) {
  const sides = [edges?.length1, edges?.length2, edges?.width1, edges?.width2];
  const matchAll =
    Boolean(sides.filter(Boolean).length) &&
    sides.every((val) => val === sides[0]);

  const res = {
    l1: sides[0] || undefined,
    l2: sides[1] || undefined,
    w1: sides[2] || undefined,
    w2: sides[3] || undefined,
    all: '',
    matchAll,
  };

  if (matchAll) {
    res.all = sides[0] as string;
    res.l1 = res.l2 = res.w1 = res.w2 = undefined;
  }
  return res;
}
export type InitialEdges = ReturnType<typeof parseEdgeBanding>;
