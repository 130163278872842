import { Material, MaterialGroup, PartItem } from '@cutr/constants/cutlist';
import { BackendCutlistGroup } from '@cutr/constants/cutlist-backend';
import {
  NestingConfig,
  NestingInput,
  NestingOutput,
  Orientation,
  SheetConfig,
} from '@cutr/constants/cutlist-nesting';
import { defaultSheetEdgeTrimConfigAllSides } from '@cutr/constants/cutlist-theme';
import {
  MATERIAL_TYPE,
  MaterialType,
  SupplierMaterial,
} from '@cutr/constants/material';
import * as Sentry from '@sentry/react';

import {
  getEdgeTrimsFromMaterialGroup,
  getMaterial,
  getMaterialsFromMaterialGroup,
  getPricingMaterialsFromPart,
} from '@/api/materials';
import { SheetGroup, useNestingStatusStore } from '@/api/nesting';
import { isValidPart } from '@/api/store';
import { PartFitError, PartFitErrorData } from '@/nesting/helpers';
import { CutlistNesting } from '@/nesting/nesting';

import { SheetMaterials } from './edgeband';
import { isTruthy } from './misc';

export type NestingGroup = {
  materialGroup: MaterialGroup;
  parts: PartItem[];
};

/**
 * Create backend grouping from the frontend material groups.
 *
 * @param sheetGroups - contains information that updates on every nesting run
 *                    (every info not related to parts will be stale)
 * @param groups - contains information that doesn't
 *                 trigger nesting (like edge profiling or paint color)
 **/
export const createBackendGrouping = (
  sheetGroups: SheetGroup[],
  groups: MaterialGroup[]
) => {
  return sheetGroups
    .map((sheetGroup, groupIndex) => {
      const {
        // can be stale when material-group-level data is updated but
        // nesting hasn't ran yet
        //
        // ex: update paint color but don't change any part data
        materialGroup: staleMaterialGroup,
        parts,
        ...nestingOutput
      } = sheetGroup;

      const materialGroup =
        groups.find((g) => g.id === staleMaterialGroup.id) ||
        staleMaterialGroup;

      const groupParts = parts.filter(isValidPart);
      if (!groupParts.length) return;
      const groupBackendParts = groupParts
        .sort(
          (a, b) =>
            ((a.createdAt || a.index) ?? 0) - ((b.createdAt || b.index) ?? 0)
        )
        .map((part, index) => ({
          index: index,
          groupId: part.groupId,
          label: part.label,
          quantity: part.quantity,
          grainDirection: part.grainDirection,
          createLabel: part.createLabel,
          widthMM: part.widthMM,
          lengthMM: part.lengthMM,
          thicknessUM: part.thickness,
          partType: part.partType,
          cncSeconds: part.cncSeconds,
          grooves: part.grooves,
          length1RoundedEdgeband: part.roundedEdgeband?.length1,
          length2RoundedEdgeband: part.roundedEdgeband?.length2,
          width1RoundedEdgeband: part.roundedEdgeband?.width1,
          width2RoundedEdgeband: part.roundedEdgeband?.width2,
          aiSuggestions: part.aiSuggestions,
          aiMeta: part.aiMeta,
          ...getPricingMaterialsFromPart(part),
        }));

      const core1 = getMaterial(materialGroup.core1);
      const automaticSheetSizeMaterialIds =
        materialGroup.automaticSheetSizeMaterials.map(
          (material) => getMaterial(material)?.id
        );
      return {
        parts: groupBackendParts,
        materialGroup: {
          nestingOutput,
          ulid: materialGroup.ulid,
          index: groupIndex + 1,
          groupId: materialGroup.id,
          name: core1?.name || materialGroup.name,
          edgeProfileType: materialGroup.edgeProfile,
          continuousGrain: materialGroup.continuousGrain,
          additionalProcessing: materialGroup.additionalProcessing,
          paintColor: materialGroup.paintColor,
          paintThicknessUM: materialGroup.paintThicknessUM,
          createLabels: materialGroup.createLabels,
          aiSuggestions: materialGroup.aiSuggestions,
          automaticSheetSizeMaterialIds,
          ...getMaterialsFromMaterialGroup(materialGroup),
          ...getEdgeTrimsFromMaterialGroup(materialGroup),
        },
      } as BackendCutlistGroup;
    })
    .filter(isTruthy);
};

export function getSizes(sheetMaterials: SheetMaterials) {
  // if sheet materials have two core materials to glue and if both of them are melamine, then we trim 0.5mm from each.
  const melamineTypes = [
    MATERIAL_TYPE.melamine_chipboard,
    MATERIAL_TYPE.melamine_mdf,
  ] as MaterialType[];

  const shouldTrimThickness =
    sheetMaterials.core1 &&
    sheetMaterials.core2 &&
    melamineTypes.includes(sheetMaterials.core1?.type) &&
    melamineTypes.includes(sheetMaterials.core2?.type);

  const smallestRectangle = (materials: (Material | undefined)[]) => {
    const sizes = materials.filter(isTruthy).map((material) => ({
      width: material.widthMM,
      length: material.lengthMM,
      thicknessUM: material.thicknessUM,
    }));

    if (!sizes.length) {
      return { width: 0, length: 0, thickness: 0 };
    }

    const widths = sizes.map(({ width }) => width);
    const lengths = sizes.map(({ length }) => length);
    const thiccs = sizes.map(({ thicknessUM }) => thicknessUM);
    const width = Math.min(...widths);
    const length = Math.min(...lengths);
    const thickness = thiccs.reduce((tot, t) => (tot += t), 0);

    return {
      width,
      length,
      thickness: shouldTrimThickness ? thickness - 1000 : thickness,
    };
  };

  // We always keep the grain direction of the hpl layers unmodified (ie, along the length).
  // In some cases, it makes sense to rotate the core material 90 degrees to maximize the sheet size.
  // For example, if core material size is 1300x3050 and hpl size is 3050x1300, we would like to rotate the core material
  // and have a sheet size of 3050x1300, instead of gluing them as they are and resulting in sheet size of 1300x1300.
  // This logic picks the core material orientation that yields the largest area. In case if both orientations yield the same area,
  // it picks the one with the largest length.
  const rectangle1 = smallestRectangle([
    sheetMaterials.topHpl,
    sheetMaterials.core1,
    sheetMaterials.core2,
    sheetMaterials.bottomHpl,
  ]);
  // If it's only a core material, we don't rotate it.
  if (!sheetMaterials.topHpl && !sheetMaterials.bottomHpl) return rectangle1;

  const rectangle2 = smallestRectangle([
    sheetMaterials.topHpl,
    sheetMaterials.core1 // rotate core materials
      ? {
          ...sheetMaterials.core1,
          widthMM: sheetMaterials.core1.lengthMM,
          lengthMM: sheetMaterials.core1.widthMM,
        }
      : undefined,
    sheetMaterials.core2
      ? {
          ...sheetMaterials.core2,
          widthMM: sheetMaterials.core2.lengthMM,
          lengthMM: sheetMaterials.core2.widthMM,
        }
      : undefined,
    sheetMaterials.bottomHpl,
  ]);

  if (
    rectangle1.length * rectangle1.width >
    rectangle2.length * rectangle2.width
  )
    return rectangle1;

  if (
    rectangle1.length * rectangle1.width <
    rectangle2.length * rectangle2.width
  )
    return rectangle2;

  if (rectangle1.length > rectangle2.length) return rectangle1;
  return rectangle2;
}

export function getSheetDimensions(group: MaterialGroup) {
  const { core1, core2, topHpl, bottomHpl } = group;

  let { thickness, width, length } = getSizes({
    core1: getMaterial(core1),
    core2: getMaterial(core2),
    topHpl: getMaterial(topHpl),
    bottomHpl: getMaterial(bottomHpl),
  });

  length -= group.sheetEdgeTrimConfig?.trimThickness.width1TrimThicknessMM || 0;
  length -= group.sheetEdgeTrimConfig?.trimThickness.width2TrimThicknessMM || 0;
  width -= group.sheetEdgeTrimConfig?.trimThickness.length1TrimThicknessMM || 0;
  width -= group.sheetEdgeTrimConfig?.trimThickness.length2TrimThicknessMM || 0;

  return { thickness, width, length };
}

export function partFitsMaterial(
  part: PartItem,
  partOrientation: Orientation,
  material: SupplierMaterial
): boolean {
  // This function is very similar to partFitsSheet.
  // We can't use partFitsSheet here due to sheet configs, etc, but probably we can merge them in the future.
  const partHasGrain = part.grainDirection === 'along';
  const materialHasGrain =
    material.hasGrainDirection || material.hasTextureDirection;

  if (partOrientation === 'vertical' && partHasGrain && materialHasGrain)
    return false;

  const partLength =
    partOrientation === 'horizontal' ? part.lengthMM : part.widthMM;
  const partWidth =
    partOrientation === 'horizontal' ? part.widthMM : part.lengthMM;

  return partLength <= material.lengthMM && partWidth <= material.widthMM;
}

export function getSmallestFittingMaterial(
  parts: PartItem[],
  automaticSheetSizeMaterials: SupplierMaterial['articleCode'][]
) {
  // if no parts (ie, empty group), return the first sheet size to get started
  if (!parts.length) return automaticSheetSizeMaterials[0];
  const smallestFittingMaterial = automaticSheetSizeMaterials
    .map(getMaterial)
    .filter(isTruthy)
    .find((material) =>
      parts.every(
        (part) =>
          partFitsMaterial(part, 'horizontal', material) ||
          partFitsMaterial(part, 'vertical', material)
      )
    );

  if (smallestFittingMaterial) return smallestFittingMaterial.articleCode;

  // none of the materials fit, return the last (largest) one
  return automaticSheetSizeMaterials[automaticSheetSizeMaterials.length - 1];
}

export function groupPartsByMaterial(parts: PartItem[]) {
  const groupMap = new Map<string, PartItem[]>();
  parts.filter(isValidPart).forEach((part) => {
    const materials = [part.core1, part.core2, part.topHpl, part.bottomHpl];

    if (!Object.values(materials).filter(Boolean).length) return;

    const uniqueId = Object.values(materials).join(':');

    if (!groupMap.has(uniqueId)) {
      groupMap.set(uniqueId, []);
    }

    const data = groupMap.get(uniqueId) as PartItem[];

    data.push(part);
  });

  return groupMap;
}

export function groupPartsForNesting(
  parts: PartItem[],
  groups: MaterialGroup[]
) {
  const isMaterialGroupValid = (group: MaterialGroup) =>
    [group.core1, group.core2, group.topHpl, group.bottomHpl].filter(Boolean)
      .length > 0;

  return groups.filter(isMaterialGroupValid).reduce((acc, materialGroup) => {
    const validParts = parts
      .filter((part) => part.groupId === materialGroup.id)
      .filter(isValidPart);

    if (!validParts.length) return acc;

    if (!acc[materialGroup.id])
      acc[materialGroup.id] = { materialGroup, parts: [] };

    acc[materialGroup.id].parts = [
      ...acc[materialGroup.id].parts,
      ...validParts,
    ];

    return acc;
  }, {} as Record<string, NestingGroup>);
}

type SchedulerCache = {
  id: MaterialGroup['id'];
  nestingInstance: CutlistNesting;
  output?: NestingOutput;
};

export function Scheduler(
  NestingInstance: typeof CutlistNesting,
  nestingConfig: NestingConfig
) {
  const cache: SchedulerCache[] = [];
  let updateCb: (id: SchedulerCache['id'], output: NestingOutput) => void;
  let errorCb: (error: string, data?: PartFitErrorData) => void;

  const getCached = (id: SchedulerCache['id']) =>
    cache.find((item) => item.id === id);

  return {
    onUpdate(cb: typeof updateCb) {
      updateCb = cb;
    },
    onError(cb: typeof errorCb) {
      errorCb = cb;
    },
    add(id: SchedulerCache['id'], nestingInput: NestingInput) {
      const cachedItem = getCached(id);
      const nestingInstance = new NestingInstance({
        nestingInput,
        nestingConfig,
      });

      nestingInstance.nestingCompletedCallback = () => {
        useNestingStatusStore.getState().setStatus(id, 'completed');
      };

      if (cachedItem) {
        cachedItem.nestingInstance = nestingInstance;

        return;
      }

      cache.push({ id, nestingInstance });
    },
    abort() {
      cache.forEach(({ nestingInstance, id }) => {
        nestingInstance.abort();
        useNestingStatusStore.getState().setStatus(id, 'aborted');
      });
    },
    run() {
      for (const item of cache) {
        useNestingStatusStore.getState().setStatus(item.id, 'running');
        item.nestingInstance
          .run((output) => {
            updateCb(item.id, output);
          })
          .catch((error) => {
            if (error instanceof PartFitError) {
              errorCb?.(error.message, error.data);
              return;
            }

            if (error instanceof Error) errorCb?.(error.message);
            Sentry.captureException(error);
          });
      }
    },
  };
}

export function convertGroupToInput(nestingGroup: NestingGroup): NestingInput {
  const core1 = getMaterial(nestingGroup.materialGroup.core1);
  const core2 = getMaterial(nestingGroup.materialGroup.core2);
  const topHpl = getMaterial(nestingGroup.materialGroup.topHpl);
  const bottomHpl = getMaterial(nestingGroup.materialGroup.bottomHpl);

  const { width, length, thickness } = getSizes({
    core1,
    core2,
    topHpl,
    bottomHpl,
  });

  const sheetConfig: SheetConfig = {
    lengthMM: length,
    widthMM: width,
    thicknessUM: thickness,
    hasGrain: true,
    sheetEdgeTrimConfig:
      nestingGroup.materialGroup.sheetEdgeTrimConfig ||
      defaultSheetEdgeTrimConfigAllSides,
  };

  const parts = nestingGroup.parts.map((part) => ({
    id: part.id,
    name: part.label,
    lengthMM: +part.lengthMM,
    widthMM: +part.widthMM,
    quantity: +part.quantity,
    hasGrain: part.grainDirection === 'along' ? true : false,
    partType: part.partType,
  }));

  return { sheetConfig, parts };
}
