import {
  CUTLIST_DOWNLOADABLE_ORDER_STATUS_OPTIONS,
  CUTLIST_ORDER_STATUS_OPTIONS,
  CutlistOrderStatus,
  CutlistOrderSummary,
} from '@cutr/constants/cutlist';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import { useAgentWorkspaceFilterStore } from '@/api/agentWorkspaceFilterStore';
import { agentApi, PaginatedData } from '@/api/backend/agent';
import { BasePagination } from '@/blocks/BasePagination';
import { Multiselect } from '@/blocks/Multiselect';
import Badge, { BadgeVariant } from '@/primitives/Badge';
import { Button, RouterButton } from '@/primitives/Button';
import {
  CutCircle,
  Download,
  Duplicate,
  Edit,
  Email,
  Icon,
  View,
} from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { useGetAgents } from '@/queries/agent';
import { agentKeys } from '@/queries/keys';
import { useCurrentFeatures } from '@/theme';
import { saveBlob } from '@/utils/file';
import { currencyFormatter, dateFormatter } from '@/utils/format';
import { useDebounce } from '@/utils/hooks';

import styles from './AgentOrdersPage.module.css';

interface AgentOrdersContextType {
  data: PaginatedData<CutlistOrderSummary>;
  setData: (data: PaginatedData<CutlistOrderSummary>) => void;
  replaceOrder: (order: CutlistOrderSummary) => void;
}

const AgentOrdersContext = createContext<AgentOrdersContextType | null>(null);

export const AgentOrdersProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [data, setOrders] = useState<PaginatedData<CutlistOrderSummary>>({
    rows: [],
    totalPages: 0,
    totalRows: 0,
  });

  const setData = (data: PaginatedData<CutlistOrderSummary>) => {
    setOrders(data);
  };

  const replaceOrder = (order: CutlistOrderSummary) => {
    setOrders((prev) => {
      return {
        ...prev,
        rows: prev.rows?.map((row) => (row.id === order.id ? order : row)),
      };
    });
  };

  return (
    <AgentOrdersContext.Provider
      value={{
        data,
        setData,
        replaceOrder,
      }}
    >
      {children}
    </AgentOrdersContext.Provider>
  );
};

export const useAgentOrders = () => {
  const context = useContext(AgentOrdersContext);
  if (!context) {
    throw new Error(
      'useAgentOrders must be used within an AgentOrdersProvider'
    );
  }
  return context;
};

type Column<T> = {
  accessorKey: keyof T;
  cell?: ({ row }: { row: T }) => React.ReactNode;
};

export const AgentOrdersPage = () => {
  return (
    <AgentOrdersProvider>
      <AgentOrders />
    </AgentOrdersProvider>
  );
};

const AgentOrders = () => {
  const { search, statuses, dateFilters, pagination, setPagination, agentIds } =
    useAgentWorkspaceFilterStore();
  const debouncedSearch = useDebounce(search, 700, search);
  const debouncedStatuses = useDebounce(statuses, 500, statuses);
  const debouncedAgentIds = useDebounce(agentIds, 500, agentIds);

  const lastSearch = React.useRef(debouncedSearch);
  const { setData } = useAgentOrders();

  const { agentWorkspaceDisabledColumns } = useCurrentFeatures();

  const queryClient = useQueryClient();

  // @ts-ignore
  const columns: Column<CutlistOrderSummary>[] = [
    {
      accessorKey: 'cutlistEmailOrderId',
      cell: ({ row }: { row: CutlistOrderSummary }) =>
        row.cutlistEmailOrderId ? <Icon icon={<Email />} /> : '',
    },
    {
      accessorKey: 'title',
    },
    {
      accessorKey: 'createdAt',
      cell: ({ row }: { row: CutlistOrderSummary }) =>
        dateFormatter(row.createdAt),
    },
    {
      accessorKey: 'shortId',
    },
    {
      accessorKey: 'agent',
      cell: ({ row }: { row: CutlistOrderSummary }) =>
        row.authorAgent ? row.authorAgent.email : '',
    },
    {
      accessorKey: 'companyName',
    },
    {
      accessorKey: 'customerName',
    },
    {
      accessorKey: 'customerEmail',
    },
    {
      accessorKey: 'status',
      cell: ({ row }: { row: CutlistOrderSummary }) => <StatusBadge {...row} />,
    },
    {
      accessorKey: 'totalAmountExclVAT',
      cell: ({ row }: { row: CutlistOrderSummary }) =>
        row.totalAmountExclVAT
          ? currencyFormatter(row.totalAmountExclVAT / 100, row.currency)
          : '-',
    },
  ].filter(
    (column) => !agentWorkspaceDisabledColumns.includes(column.accessorKey)
  );

  React.useEffect(() => {
    const { createdAfter, createdBefore, submittedAfter, submittedBefore } =
      dateFilters;
    const queryParams = {
      ...pagination,
      ...(debouncedSearch ? { search: debouncedSearch } : {}),
      ...(debouncedStatuses && debouncedStatuses.length > 0
        ? { statuses: debouncedStatuses }
        : {}),
      ...(debouncedAgentIds && debouncedAgentIds.length > 0
        ? { agentIds: debouncedAgentIds }
        : {}),
      ...(createdAfter ? { createdAfter } : {}),
      ...(createdBefore ? { createdBefore } : {}),
      ...(submittedAfter ? { submittedAfter } : {}),
      ...(submittedBefore ? { submittedBefore } : {}),
    };

    queryClient
      .fetchQuery<PaginatedData<CutlistOrderSummary>>({
        queryKey: agentKeys.getCutlists(queryParams),
        queryFn: () => agentApi.getCutlists(queryParams),
      })
      .then((data) => {
        setData(data);
        if (lastSearch.current !== debouncedSearch) {
          setPagination({
            pageSize: pagination.pageSize,
            pageIndex: 0,
          });
          lastSearch.current = debouncedSearch;
        }
      });
  }, [
    pagination,
    debouncedSearch,
    debouncedStatuses,
    dateFilters,
    debouncedAgentIds,
  ]);

  return (
    <main className={styles.content}>
      <Filters />
      <div className={styles.orderSection}>
        <AgentOrdersTable columns={columns} />
      </div>
    </main>
  );
};

type AgentOrdersTableProps = {
  columns: Column<CutlistOrderSummary>[];
};

const AgentOrdersTable = ({ columns }: AgentOrdersTableProps) => {
  const { t } = useTranslation();
  const isFetching = useIsFetching({ queryKey: ['agent', 'cutlists'] });

  const { pagination, setPagination } = useAgentWorkspaceFilterStore();

  const { data } = useAgentOrders();

  const rows = data.rows;
  const pageMetadata = {
    ...pagination,
    totalRows: data.totalRows ?? 0,
    totalPages: data.totalPages ?? 0,
  };

  return (
    <>
      <table>
        <thead>
          <tr>
            {columns.map(({ accessorKey }) => (
              <th key={accessorKey}>{t(`agent.${accessorKey}`)}</th>
            ))}
            <th>{t('agent.actions')}</th>
          </tr>
        </thead>
        <tbody>
          <Rows rows={rows} columns={columns} />
        </tbody>
      </table>
      <div className="flexAlign opposites">
        <div style={{ width: '100%' }}>
          <BasePagination
            totalPages={pageMetadata.totalPages}
            currentPage={pageMetadata.pageIndex + 1}
            disabled={isFetching > 0}
            onPageChanged={(page) => {
              setPagination({
                pageSize: pageMetadata.pageSize,
                pageIndex: page - 1,
              });
            }}
          />
        </div>
        <div className="flexAlign">
          <span style={{ whiteSpace: 'nowrap' }}>{t('agent.show')}</span>
          {[10, 30, 50].map((pageSize) => (
            <Button
              key={pageSize}
              size="xs"
              className={pageMetadata.pageSize !== pageSize ? 'outline' : ''}
              disabled={isFetching > 0}
              onClick={() =>
                pageMetadata.pageSize !== pageSize &&
                setPagination({
                  pageSize,
                  pageIndex: 0,
                })
              }
            >
              {pageSize}
            </Button>
          ))}
        </div>
      </div>
    </>
  );
};

const Rows = ({
  rows,
  columns,
}: {
  rows?: CutlistOrderSummary[];
  columns: Column<CutlistOrderSummary>[];
}) => {
  const isFetching = useIsFetching({ queryKey: ['agent', 'cutlists'] });

  if (rows && rows.length > 0) {
    return (
      <>
        {rows.map((row) => (
          <TableRow key={row.id} row={row} columns={columns} />
        ))}
      </>
    );
  }

  if (rows && rows.length === 0) {
    return <NoResultsRow columns={columns} />;
  }

  if (isFetching) {
    return (
      <>
        {Array.from({ length: 10 }).map((_, index) => (
          <SkeletonRow key={index} columns={columns} />
        ))}
      </>
    );
  }

  return <NoResultsRow columns={columns} />;
};

const TableRow = ({
  row,
  columns,
}: {
  row: CutlistOrderSummary;
  columns: Column<CutlistOrderSummary>[];
}) => {
  return (
    <tr key={row.id}>
      {columns.map(({ accessorKey, cell }) => {
        return (
          <td key={`${accessorKey} + ${row.id}`}>
            {/* @ts-ignore good luck */}
            {cell ? cell({ row }) : row[accessorKey]}
          </td>
        );
      })}
      <td>
        <RowActions cutlist={row} />
      </td>
    </tr>
  );
};

const SkeletonRow = ({
  columns,
}: {
  columns: Column<CutlistOrderSummary>[];
}) => {
  return (
    <tr style={{ opacity: 0.7 }}>
      {columns.map((_, index) => (
        <td key={index} className={styles.loading}>
          <div className={styles.bar}></div>
        </td>
      ))}
      <td>
        <div className={styles.actions}>
          <Button className="smaller pill snug outline" disabled={true}>
            <Icon icon={<Download />} />
          </Button>
          <Button className="smaller pill snug outline" disabled={true}>
            <Icon icon={<View />} />
          </Button>
          <Button className="smaller pill snug outline" disabled={true}>
            <Icon icon={<Edit />} />
          </Button>
        </div>
      </td>
    </tr>
  );
};

const NoResultsRow = ({
  columns,
}: {
  columns: Column<CutlistOrderSummary>[];
}) => {
  const { t } = useTranslation();
  return (
    <tr>
      <td
        colSpan={columns.length}
        style={{ textAlign: 'center', padding: '20px' }}
      >
        {t('agent.noResultsFound')}
      </td>
    </tr>
  );
};

const StatusBadge = ({
  status,
  updatedAt,
  submittedAt,
  quoteReadyAt,
  cancelledAt,
  llmAnalysisStartedAt,
}: Partial<CutlistOrderSummary>) => {
  const { t } = useTranslation();

  const getHoverText = () => {
    switch (status) {
      case 'draft':
        return {
          tooltip: updatedAt
            ? `${t('agent.updatedAt')}: ${dateFormatter(updatedAt)}`
            : '',
          badgeVariant: 'default',
        };
      case 'pending_analysis':
        return {
          tooltip: llmAnalysisStartedAt
            ? `${t('agent.llmAnalysisStartedAt')}: ${dateFormatter(
                llmAnalysisStartedAt
              )}`
            : '',
          badgeVariant: 'signal',
        };
      case 'quote_requested':
        return {
          tooltip: submittedAt
            ? `${t('agent.submittedAt')}: ${dateFormatter(submittedAt)}`
            : '',
          badgeVariant: 'signal',
        };
      case 'quote_ready':
        return {
          tooltip: quoteReadyAt
            ? `${t('agent.quote_ready')}: ${dateFormatter(
                quoteReadyAt as string
              )}`
            : '',
          badgeVariant: 'new',
        };
      case 'order_placed':
        return {
          tooltip: submittedAt
            ? `${t('agent.submittedAt')}: ${dateFormatter(
                submittedAt as string
              )}`
            : '',
          badgeVariant: 'success',
        };
      case 'cancelled':
        return {
          tooltip: cancelledAt
            ? `${t('agent.cancelledAt')}: ${dateFormatter(
                cancelledAt as string
              )}`
            : '',
          badgeVariant: 'alert',
        };
      default:
        return {
          tooltip: '',
          badgeVariant: 'default',
        };
    }
  };

  const { tooltip, badgeVariant } = getHoverText();

  return (
    <div className={tooltip ? 'tooltip' : ''} data-tooltip={tooltip}>
      <Badge variant={badgeVariant as BadgeVariant}>
        {t(`agent.${status}`)}
      </Badge>
    </div>
  );
};

const Filters = () => {
  const { t } = useTranslation();
  const {
    dateFilters,
    setDateFilters,
    search,
    setSearch,
    statuses,
    setStatuses,
    agentIds,
    setAgentIds,
  } = useAgentWorkspaceFilterStore();
  const { createdAfter, createdBefore, submittedAfter, submittedBefore } =
    dateFilters;

  const { data: agents } = useGetAgents();

  const agentsList = React.useMemo(() => {
    if (agents) {
      return agents;
    }
    return [];
  }, [agents]);

  return (
    <div className={styles.filterSection}>
      <div className={styles.filterField}>
        <label>{t('agent.filters.search')}</label>
        <Input
          type="text"
          placeholder={t('agent.search')}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>

      <div className={styles.filterField}>
        <label>{t('agent.filters.status')}</label>
        <Multiselect
          id="status"
          value={t('agent.statuses')}
          options={CUTLIST_ORDER_STATUS_OPTIONS.map((value) => ({
            label: t(`agent.${value}`),
            value,
            selected: statuses.includes(value),
            component: () => (
              <StatusBadge status={value as CutlistOrderStatus} />
            ),
          }))}
          onSelected={(selectedValues) => {
            setStatuses([...(selectedValues as CutlistOrderStatus[])]);
          }}
          className={styles.filterSelect}
        />
      </div>

      <div className={styles.filterField}>
        <label>{t('agent.filters.agents')}</label>
        <Multiselect
          id="agents"
          key={JSON.stringify(agentsList)}
          value={t('agent.agents')}
          options={agentsList.map((value: { email?: string; id?: string }) => ({
            label: value.email || '',
            value: value.id || '',
            selected: value.id ? agentIds.includes(value.id) : false,
            component: () => <div>{value.email || ''}</div>,
          }))}
          onSelected={(selectedValues) =>
            setAgentIds([...(selectedValues as string[])])
          }
          className={styles.filterSelect}
        />
      </div>

      <div className={styles.filterField}>
        <label>{t('agent.filters.createdAfter')}</label>
        <Input
          type="date"
          value={createdAfter}
          onChange={(e) =>
            setDateFilters({ ...dateFilters, createdAfter: e.target.value })
          }
        />
      </div>

      <div className={styles.filterField}>
        <label>{t('agent.filters.createdBefore')}</label>
        <Input
          type="date"
          value={createdBefore}
          onChange={(e) =>
            setDateFilters({ ...dateFilters, createdBefore: e.target.value })
          }
        />
      </div>

      <div className={styles.filterField}>
        <label>{t('agent.filters.submittedAfter')}</label>
        <Input
          type="date"
          value={submittedAfter}
          onChange={(e) =>
            setDateFilters({ ...dateFilters, submittedAfter: e.target.value })
          }
        />
      </div>

      <div className={styles.filterField}>
        <label>{t('agent.filters.submittedBefore')}</label>
        <Input
          type="date"
          value={submittedBefore}
          onChange={(e) =>
            setDateFilters({ ...dateFilters, submittedBefore: e.target.value })
          }
        />
      </div>
    </div>
  );
};

type RowActionsProps = {
  cutlist: CutlistOrderSummary;
};

const RowActions = ({ cutlist }: RowActionsProps) => {
  const { t } = useTranslation();
  const [preparingZip, setPreparingZip] = React.useState(false);
  const [duplicating, setDuplicating] = React.useState(false);
  const [cancellingAnalysis, setCancellingAnalysis] = React.useState(false);
  const { agentQuoteFlow } = useCurrentFeatures();
  const navigate = useNavigate();
  const editUrl = agentQuoteFlow
    ? `${cutlist.id}/quote/parts`
    : `${cutlist.id}/edit`;
  const { replaceOrder } = useAgentOrders();

  const handleCancelAnalysis = async () => {
    setCancellingAnalysis(true);
    agentApi
      .cancelAnalysis(cutlist.id)
      .then((data) => {
        toast.success(t('agent.llmAnalysisCancelled'));
        replaceOrder(data);
      })
      .finally(() => {
        setCancellingAnalysis(false);
      });
  };

  const isAnalysisOverdue = wasMoreThanNMinutesAgo(
    5,
    cutlist.llmAnalysisStartedAt
  );

  if (cutlist.status === 'pending_analysis' && isAnalysisOverdue) {
    return (
      <div className={styles.actions}>
        <Button
          className="smaller pill snug outline"
          onClick={handleCancelAnalysis}
          icon={<Icon icon={<CutCircle />} />}
          disabled={cancellingAnalysis ? true : false}
        >
          {t('agent.cancelAnalysis')}
        </Button>
      </div>
    );
  }

  if (cutlist.status === 'pending_analysis') {
    return;
  }

  const handleDownload = async () => {
    setPreparingZip(true);
    agentApi
      .getCutlistZip(cutlist.id)
      .then((result) => {
        saveBlob(result, `${cutlist.shortId}.zip`);
      })
      .finally(() => {
        setPreparingZip(false);
      });
  };

  const handleDuplicate = async () => {
    setDuplicating(true);
    agentApi
      .duplicateCutlist(cutlist.id)
      .then((data) => {
        navigate(`/admin/orders/${data.id}/quote/parts`);
      })
      .finally(() => {
        setDuplicating(false);
      });
  };

  return (
    <div className={styles.actions}>
      {CUTLIST_DOWNLOADABLE_ORDER_STATUS_OPTIONS.includes(cutlist.status) && (
        <Button
          className="smaller pill snug outline"
          onClick={handleDownload}
          disabled={preparingZip ? true : false}
        >
          <Icon icon={<Download />} />
        </Button>
      )}
      {!cutlist.cancelledAt && (
        <>
          <RouterButton className="smaller pill snug outline" to={editUrl}>
            <Icon icon={<Edit />} />
          </RouterButton>
        </>
      )}
      <Button
        className="smaller pill snug outline"
        onClick={handleDuplicate}
        disabled={duplicating ? true : false}
      >
        <Icon icon={<Duplicate />} />
      </Button>
    </div>
  );
};

const wasMoreThanNMinutesAgo = (minutes: number, date?: string | null) => {
  if (!date) {
    return false;
  }

  return new Date(date).getTime() < Date.now() - minutes * 60 * 1000;
};
