import { CutlistState } from '@cutr/constants/cutlist';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useIsFeatureEnabled } from '@/api/featureFlags';
import { useCutlistState } from '@/api/store';
import { useThemeConfig } from '@/theme';

type PageTitleFunctionOptions = {
  isFeatureEnabled: ReturnType<typeof useIsFeatureEnabled>;
  t: TFunction;
  cutlist: CutlistState;
  location: ReturnType<typeof useLocation>;
};

type PageTitleMap = {
  [key: string]: string | ((options: PageTitleFunctionOptions) => string);
};

const PAGE_TITLE_MAP: PageTitleMap = {
  root: ({ t }) => t('common.pageTitles.orders'),

  view: ({ t, cutlist }) =>
    t('common.pageTitles.view', {
      title: cutlist.title,
      shortId: cutlist.shortId,
    }),

  cutlist: ({ cutlist, t, location }) => {
    if (location.pathname.includes('done')) {
      return t('common.pageTitles.submitted');
    }
    return t('common.pageTitles.draft', { title: cutlist.title });
  },

  admin: ({ cutlist, t }) => {
    if (location.pathname.includes('orders')) {
      return t('common.pageTitles.allOrders');
    }
    return t('common.pageTitles.shortId', {
      shortId: cutlist.shortId || '',
    });
  },

  login: 'common.pageTitles.login',
};

const DELIMITER = ' | ';

export const useLocationPageTitle = () => {
  const isFeatureEnabled = useIsFeatureEnabled();
  const cutlist = useCutlistState();
  const location = useLocation();

  const { t } = useTranslation();
  const { setPageTitle } = usePageTitle();

  React.useEffect(() => {
    const key = location.pathname.split('/')[1];
    const title = PAGE_TITLE_MAP[key || 'root'];

    let pageTitle = '';

    if (typeof title === 'function') {
      pageTitle = title({ t, cutlist, isFeatureEnabled, location });
    }
    if (title && typeof title === 'string') {
      pageTitle = t(title);
    }

    setPageTitle(pageTitle);
  }, [location.pathname, cutlist]);
};

export const usePageTitle = () => {
  const config = useThemeConfig();
  const { t } = useTranslation();

  return {
    setPageTitle: (title: string = '') => {
      const pageTitle = [
        title.trim(),
        t('common.basePageTitle', {
          sourceName: config.pageTitleName,
        }).trim(),
      ]
        .filter(Boolean)
        .join(DELIMITER);

      if (pageTitle !== document.title) {
        document.title = pageTitle;
      }
    },
  };
};
