import { EdgeGroove, PartGrooves, PartItem } from '@cutr/constants/cutlist';
import i18n from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { useFeatureFlag } from '@/api/featureFlags';
import { getMaterial } from '@/api/materials';
import { useActiveGroup } from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';
import { InfoMessage } from '@/blocks/InfoMessage';
import { Modal } from '@/blocks/Modal';
import { Button } from '@/primitives/Button';
import { Checkbox } from '@/primitives/Checkbox';
import { Cogwheel, Icon } from '@/primitives/Icons';
import { Input } from '@/primitives/Input';
import { cleanDecimal } from '@/utils/input';

import { formatGroupWithMerging } from './AiSuggestions';
import styles from './EdgeGroovesModal.module.css';
import {
  EdgeGrooveInputType,
  EdgeGroovesProvider,
  Edges,
  EdgeSelectorProvider,
  useEdgeGrooves,
  useEdgeSelector,
} from './EdgeProcessingContext';
import { EdgesSelector } from './EdgeSelector';
import { GroovePreview } from './GroovePreview';
import { useSelectedPartsContext } from './SelectedPartsContext';

export const EditEdgeGrooves = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const { selectedParts } = useSelectedPartsContext();

  const hasLlmFlowEnabled = useFeatureFlag('invofox-llm-flow');

  if (!hasLlmFlowEnabled) return null;

  const editEdgegroovesTooltip =
    selectedParts.length === 0 ? t('agent.atLeastOnePartTooltip') : undefined;

  const afterApply = () => {
    setIsOpen(false);
    toast.success(t('agent.toasts.edgeGroovingApplied') as string);
  };

  return (
    <>
      <Button
        name="edit-edgegrooves"
        disabled={selectedParts.length === 0}
        tooltip={editEdgegroovesTooltip}
        onClick={() => setIsOpen(true)}
        variant="secondary"
        icon={<Icon icon={<Cogwheel />} />}
      >
        {t('agent.editEdgeGrooves')}
      </Button>
      <Modal
        title={t('agent.edgebandConfiguration.title')}
        handleClose={() => setIsOpen(false)}
        isOpen={isOpen}
      >
        <ModalContent afterApply={afterApply} />
      </Modal>
    </>
  );
};

const ModalContent = ({ afterApply }: { afterApply: () => void }) => {
  const { t } = useTranslation();
  const [perEdge, setPerEdge] = React.useState(false);
  const { selectedParts } = useSelectedPartsContext();
  const initialGrooves = React.useMemo(() => {
    return parseEdgeGrooves(selectedParts);
  }, [selectedParts]);

  const initialEdges = React.useMemo(() => {
    return parseEdges(selectedParts);
  }, [selectedParts]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPerEdge(e.target.checked);
  };

  return (
    <EdgeSelectorProvider initialEdgesToApply={initialEdges}>
      <EdgeGroovesProvider initialState={initialGrooves}>
        <div className="stack">
          <AiSuggestions />
          <h3>{t('agent.editEdgeGrooves')}</h3>
          <Checkbox isSwitch defaultChecked={perEdge} onChange={onChange}>
            {t('agent.edgebandConfiguration.differentGroovesForAll')}
          </Checkbox>
          {perEdge && <MultipleEdgesConfig afterApply={afterApply} />}
          {!perEdge && <SingleEdgeConfig afterApply={afterApply} />}
        </div>
      </EdgeGroovesProvider>
    </EdgeSelectorProvider>
  );
};

export const SingleEdgeConfig = ({
  afterApply,
}: {
  afterApply: () => void;
}) => {
  const { t } = useTranslation();
  const { setPart } = useCutlistState();
  const { groovesPerEdge } = useEdgeGrooves();
  const { edgesToApply, setEdgesToApply } = useEdgeSelector();
  const { selectedParts } = useSelectedPartsContext();
  const activeGroup = useActiveGroup();
  const { clearState } = useEdgeGrooves();

  const core1Material = getMaterial(activeGroup?.core1);

  const { position, dimensions } = groovesPerEdge['all'];

  const handleApplyEdgeGrooves = (e: React.FormEvent) => {
    e.preventDefault();

    selectedParts.forEach((part) => {
      const newPart = { ...part };

      newPart.grooves = {};

      ['length1', 'length2', 'width1', 'width2'].forEach((edge) => {
        // @ts-expect-error
        if (['off', 'mixed'].includes(edgesToApply[edge])) return;

        if (position !== 'none') {
          newPart.grooves = {
            ...newPart.grooves,
            [edge]: { position, ...cleanDimensions(dimensions) },
          };
        }

        if (position === 'none' || !position) {
          newPart.grooves = {
            ...newPart.grooves,
            [edge]: {
              position: 'none',
              offset: 0,
              width: 0,
              depth: 0,
            },
          };
        }
      });

      setPart(newPart);
    });

    clearState();
    afterApply();
  };

  const resetEdges = (val: boolean) => {
    setEdgesToApply({
      length1: val ? 'on' : 'off',
      length2: val ? 'on' : 'off',
      width1: val ? 'on' : 'off',
      width2: val ? 'on' : 'off',
    });
  };

  const hasMixedEdges = Object.values(edgesToApply).some(
    (edgeState) => edgeState === 'mixed'
  );

  const hasMixedDimensions = () => {
    if (position === 'none') {
      return false;
    }

    return (
      ['offset', 'width', 'depth'].some(
        (dimension) =>
          !isDimensionValid(
            dimensions[dimension as keyof EdgeGrooveInputType['dimensions']]
          )
      ) || !position
    );
  };

  const hasMixedState = hasMixedEdges || hasMixedDimensions();

  const getTooltip = () => {
    if (hasMixedEdges) return t('agent.edgebandConfiguration.mixedEdges');
    if (hasMixedDimensions())
      return t('agent.edgebandConfiguration.mixedDimensions');
    return undefined;
  };

  return (
    <>
      <div className={styles.previewGrid}>
        <div>
          <h4 className={styles.subtitle}>{t('agent.edgeGroove.title')}</h4>
          <EdgeGroovePosition edge="all" />
          <EdgeGrooveDimensions edge="all" />
        </div>
        <div>
          <h4 className={styles.subtitle}>{''}</h4>

          <div className="flexAlign column gap-xs end">
            <i>{t('agent.top')}</i>
            <div style={{ position: 'relative' }}>
              <GroovePreview position={position ?? 'none'} />
              {Boolean(core1Material?.thicknessMM) && (
                <>
                  {' '}
                  <ThicknessArrow />
                  <div
                    style={{
                      position: 'absolute',
                      color: 'white',
                      top: '50%',
                      right: 10,
                      transform: 'translateY(-50%)',
                    }}
                  >
                    {core1Material?.thicknessMM} {'mm'}
                  </div>
                </>
              )}
            </div>
            <i>{t('agent.bottom')}</i>
          </div>
        </div>
      </div>
      <div className={styles.previewGrid}>
        <div className={styles.preview}>
          <h4 className={styles.subtitle}>{t('agent.preview.toApplyTo')}</h4>
          <EdgesSelector>
            <strong>{t('agent.preview.willApplyTo')}</strong>
            <br />
            {t('agent.preview.selectedPartsNr', {
              count: selectedParts.length,
            })}
          </EdgesSelector>
        </div>
        <div className="flexAlign column" style={{ justifyContent: 'center' }}>
          <Button
            variant="secondaryPill"
            style={{ fontWeight: '600' }}
            onClick={() => resetEdges(true)}
          >
            {t('agent.preview.selectAll')}
          </Button>
          <Button
            variant="secondaryPill"
            style={{ fontWeight: '600' }}
            onClick={() => resetEdges(false)}
          >
            {t('agent.preview.selectNone')}
          </Button>
        </div>
      </div>
      <Button
        onClick={handleApplyEdgeGrooves}
        disabled={hasMixedState}
        tooltip={getTooltip()}
      >
        {t('agent.applyEdgeProcessing')}
      </Button>
    </>
  );
};

const ThicknessArrow = () => {
  return (
    <div
      style={{
        position: 'absolute',
        color: 'var(--white)',
        border: '1px solid var(--white)',
        top: 6,
        height: '90%',
        right: 6,
        bottom: '5',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: -6,
          right: -4,
          width: 0,
          height: 0,
          borderLeft: '4px solid transparent',
          borderRight: '4px solid transparent',
          borderBottom: '6px solid var(--white)',
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: -6,
          right: -4,
          width: 0,
          height: 0,
          borderLeft: '4px solid transparent',
          borderRight: '4px solid transparent',
          borderTop: '6px solid var(--white)',
        }}
      />
    </div>
  );
};

const MultipleEdgesConfig = ({ afterApply }: { afterApply: () => void }) => {
  const { t } = useTranslation();
  const { setPart } = useCutlistState();
  const { selectedParts } = useSelectedPartsContext();
  const { groovesPerEdge } = useEdgeGrooves();
  const activeGroup = useActiveGroup();
  const core1Material = getMaterial(activeGroup?.core1);
  const { clearState } = useEdgeGrooves();

  const handleApplyEdgeGrooves = (e: React.FormEvent) => {
    e.preventDefault();

    selectedParts.forEach((part) => {
      const newPart = { ...part };

      newPart.grooves = {};

      Object.entries(groovesPerEdge).forEach(
        ([edge, { position, dimensions }]) => {
          if (position !== 'none') {
            newPart.grooves = {
              ...newPart.grooves,
              [edge]: { position, ...cleanDimensions(dimensions) },
            };
          }

          if (position === 'none' || !position) {
            newPart.grooves = {
              ...newPart.grooves,
              [edge]: {
                position: 'none',
                offset: 0,
                width: 0,
                depth: 0,
              },
            };
          }
        }
      );

      setPart(newPart);
    });

    toast.success(t('agent.toasts.edgeProcessingApplied') as string);

    clearState();
    afterApply();
  };

  const hasMixedDimensions = ['length1', 'length2', 'width1', 'width2'].some(
    (edge) => {
      const { position, dimensions } = groovesPerEdge[edge as keyof Edges];
      if (position === 'none') return false;

      if (
        !isDimensionValid(dimensions.offset) ||
        !isDimensionValid(dimensions.width) ||
        !isDimensionValid(dimensions.depth)
      ) {
        return true;
      }

      return false;
    }
  );

  const getTooltip = () => {
    if (hasMixedDimensions)
      return t('agent.edgebandConfiguration.mixedDimensions');
    return undefined;
  };

  return (
    <div className={styles.grid}>
      {(['length1', 'length2', 'width1', 'width2'] as (keyof Edges)[]).map(
        (edge) => (
          <div key={edge}>
            <div className="flexAlign">
              <div>
                <EdgeGroovePosition edge={edge} />
                <EdgeGrooveDimensions edge={edge} />
              </div>
              <div style={{ position: 'relative' }}>
                <GroovePreview
                  position={groovesPerEdge[edge].position ?? 'none'}
                />
                {Boolean(core1Material?.thicknessMM) && (
                  <>
                    {' '}
                    <ThicknessArrow />
                    <div
                      style={{
                        position: 'absolute',
                        color: 'white',
                        top: '50%',
                        right: 10,
                        transform: 'translateY(-50%)',
                      }}
                    >
                      {core1Material?.thicknessMM} {'mm'}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )
      )}
      <Button
        onClick={handleApplyEdgeGrooves}
        disabled={hasMixedDimensions}
        tooltip={getTooltip()}
      >
        {t('agent.applyEdgeProcessing')}
      </Button>
    </div>
  );
};

const isDimensionValid = (dimension: string) => {
  return !isNaN(parseFloat(dimension));
};

const EdgeGroovePosition = ({ edge = 'all' }: { edge?: keyof Edges }) => {
  const { t } = useTranslation();
  const { groovesPerEdge, setEdgeGroove } = useEdgeGrooves();
  const { dimensions } = groovesPerEdge[edge];

  const handlePositionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEdgeGroove(edge, {
      position: event.target.value as EdgeGroove['position'],
      dimensions,
    });
  };

  return (
    <div className={styles.inputContainer}>
      <strong>
        {edge !== 'all' &&
          t(`cutlist-form.field.edgebanding.sides.${labelToKey(edge)}`)}{' '}
        {t('agent.edgeGroove.position.label')}
      </strong>
      <div className="flexAlign" style={{ paddingTop: 'var(--space-xs)' }}>
        {['none', 'top', 'side', 'bottom'].map((option) => (
          <div className="flexAlign gap-xs" key={option}>
            <input
              name={`groove-position-${edge}`}
              id={`groove-position-${edge}-${option}`}
              type="radio"
              value={option}
              style={{
                appearance: 'auto',
                margin: 0,
                accentColor: 'var(--primary)',
              }}
              onChange={(e) => handlePositionChange(e)}
              checked={groovesPerEdge[edge].position === option}
              required
            />
            <label htmlFor={`groove-position-${option}`}>
              {t(`agent.edgeGroove.position.${option}`)}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

const labelToKey = (label: keyof Edges) =>
  label.replace('length', 'l').replace('width', 'w') as keyof Edges;

const EdgeGrooveDimensions = ({ edge = 'all' }: { edge?: keyof Edges }) => {
  const { t } = useTranslation();
  const { groovesPerEdge, setEdgeGroove } = useEdgeGrooves();
  const { dimensions, position } = groovesPerEdge[edge];
  const { offset, width, depth } = dimensions;

  const handleDimensionChange =
    (dimension: 'A' | 'B' | 'C') =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const cleanedValue = cleanDecimal(event.target.value);
      setEdgeGroove(edge, {
        position,
        dimensions: { ...dimensions, [dimension]: cleanedValue },
      });
    };

  const getPlaceholderA = (position: EdgeGroove['position']) => {
    if (position === 'top')
      return t('agent.edgeGroove.dimension.placeholderOffsetSide');
    if (position === 'side')
      return t('agent.edgeGroove.dimension.placeholderOffsetTop');
    if (position === 'bottom')
      return t('agent.edgeGroove.dimension.placeholderOffsetSide');
    return '';
  };

  const getPlaceholderB = () => {
    return t('agent.edgeGroove.dimension.placeholderWidth');
  };

  const getPlaceholderC = () => {
    return t('agent.edgeGroove.dimension.placeholderDepth');
  };

  const dimensionsMeta = [
    {
      key: 'groove-dimension-A',
      dimension: 'offset',
      value: offset,
      placeholder: getPlaceholderA(position ?? 'none'),
    },
    {
      key: 'groove-dimension-B',
      dimension: 'width',
      value: width,
      placeholder: getPlaceholderB(),
    },
    {
      key: 'groove-dimension-C',
      dimension: 'depth',
      value: depth,
      placeholder: getPlaceholderC(),
    },
  ];

  return (
    <div className={styles.inputContainer}>
      <strong>
        {edge !== 'all' &&
          t(`cutlist-form.field.edgebanding.sides.${labelToKey(edge)}`)}{' '}
        {t('agent.edgeGroove.dimension.label')}
      </strong>
      <div className="flexAlign" style={{ paddingTop: 'var(--space-xs)' }}>
        {dimensionsMeta.map(({ key, dimension, value, placeholder }) => (
          <div className="flexAlign gap-xs" key={key}>
            <label htmlFor={key} className="textStrong">
              {t(`agent.edgeGroove.dimension.${dimension}`)}
              {position !== 'none' ? (
                '*'
              ) : (
                <span style={{ visibility: 'hidden' }}>*</span>
              )}
            </label>
            <Input
              name="groove-dimension"
              id={key}
              required={position !== 'none'}
              value={value}
              placeholder={placeholder}
              disabled={position === 'none'}
              onChange={(e) => {
                handleDimensionChange(dimension as 'A' | 'B' | 'C')(e);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const AiSuggestions = () => {
  const { t } = useTranslation();
  const { selectedParts } = useSelectedPartsContext();
  const hasLlmFlowEnabled = useFeatureFlag('invofox-llm-flow');

  if (!hasLlmFlowEnabled) return <p></p>;
  const suggestions = parseLlmSuggestions(selectedParts);

  if (Object.keys(suggestions).length === 0) return <p></p>;

  if (
    Object.keys(suggestions).length === 1 &&
    Object.keys(suggestions)[0] === ''
  )
    return <p></p>;

  if (Object.keys(suggestions).length > 1)
    return (
      <InfoMessage message={t('agent.quoteFlow.llmFlow.mixed')} variant="ai" />
    );

  const formatSuggestions = (suggestions: Record<string, PartItem[]>) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, value] = Object.entries(suggestions)[0];
    const part = value[0];
    const edgeGroove = {
      l1: part.aiSuggestions.length1Groove,
      l2: part.aiSuggestions.length2Groove,
      w1: part.aiSuggestions.width1Groove,
      w2: part.aiSuggestions.width2Groove,
    };

    return formatGroupWithMerging(
      edgeGroove,
      t('cutlist-form.field.aiSuggestions.grooves'),
      t
    );
  };

  return (
    <InfoMessage
      message={t('agent.quoteFlow.llmFlow.aiSuggestions', {
        suggestions: formatSuggestions(suggestions),
      })}
      variant="ai"
    />
  );
};

const parseLlmSuggestions = (selectedParts: PartItem[]) => {
  return selectedParts
    .map((part) => {
      const edgeGrooveSuggestions = [
        part.aiSuggestions.length1Groove,
        part.aiSuggestions.length2Groove,
        part.aiSuggestions.width1Groove,
        part.aiSuggestions.width2Groove,
      ]
        .filter(Boolean)
        .join('|');

      return { part, edgeGrooveSuggestions };
    })
    .reduce((acc, curr) => {
      const suggestions = curr.edgeGrooveSuggestions;
      if (!acc[suggestions]) {
        acc[suggestions] = [];
      }
      acc[suggestions].push(curr.part);
      return acc;
    }, {} as Record<string, PartItem[]>);
};

const parseEdges = (selectedParts: PartItem[]) => {
  const hasGrooveOnEdge = (edge: keyof PartGrooves) => {
    if (selectedParts.every((part) => isGrooveOnEdge(part, edge))) {
      return 'on' as const;
    }

    if (selectedParts.some((part) => isGrooveOnEdge(part, edge))) {
      return 'mixed' as const;
    }

    return 'off' as const;
  };

  return {
    length1: hasGrooveOnEdge('length1'),
    length2: hasGrooveOnEdge('length2'),
    width1: hasGrooveOnEdge('width1'),
    width2: hasGrooveOnEdge('width2'),
  };
};

const parseEdgeGrooves = (selectedParts: PartItem[]) => {
  const mixed = i18n.t('agent.mixed');
  const edges = parseEdges(selectedParts);

  const fieldDefault = {
    position: 'none',
    offset: '',
    width: '',
    depth: '',
  };

  const getValuesPerEdge = (
    edge: keyof PartGrooves,
    field: keyof EdgeGroove
  ) => {
    const set = new Set<EdgeGroove[typeof field] | undefined>();
    selectedParts.forEach((part) => {
      const value = part.grooves?.[edge]?.[field];

      set.add(value);
    });

    if (set.size === 0) return fieldDefault[field];
    if (set.size === 1) return set.values().next().value ?? fieldDefault[field];
    return field === 'position' ? null : mixed;
  };

  const getValuesForAll = (dimension: keyof EdgeGroove) => {
    const map = new Map<
      keyof PartGrooves,
      Set<EdgeGroove[typeof dimension] | undefined>
    >();

    Object.entries(edges)
      .filter(([, edgeState]) => edgeState === 'on')
      .forEach(([edge]) => {
        const set = new Set<EdgeGroove[typeof dimension] | undefined>();
        const side = edge as keyof PartGrooves;

        selectedParts.forEach((part) => {
          const value = part.grooves?.[side]?.[dimension];

          set.add(value);
        });

        map.set(side, set);
      });
    const dimensions = new Set(
      Array.from(map.entries()).flatMap(([, value]) => Array.from(value))
    );

    if (dimensions.size === 0) return fieldDefault[dimension];
    if (dimensions.size === 1)
      return dimensions.values().next().value ?? fieldDefault[dimension];

    return dimension === 'position' ? null : mixed;
  };

  const result = {
    length1: {
      position: getValuesPerEdge('length1', 'position'),
      dimensions: {
        offset: getValuesPerEdge('length1', 'offset'),
        width: getValuesPerEdge('length1', 'width'),
        depth: getValuesPerEdge('length1', 'depth'),
      },
    },

    length2: {
      position: getValuesPerEdge('length2', 'position'),
      dimensions: {
        offset: getValuesPerEdge('length2', 'offset'),
        width: getValuesPerEdge('length2', 'width'),
        depth: getValuesPerEdge('length2', 'depth'),
      },
    },
    width1: {
      position: getValuesPerEdge('width1', 'position'),
      dimensions: {
        offset: getValuesPerEdge('width1', 'offset'),
        width: getValuesPerEdge('width1', 'width'),
        depth: getValuesPerEdge('width1', 'depth'),
      },
    },
    width2: {
      position: getValuesPerEdge('width2', 'position'),
      dimensions: {
        offset: getValuesPerEdge('width2', 'offset'),
        width: getValuesPerEdge('width2', 'width'),
        depth: getValuesPerEdge('width2', 'depth'),
      },
    },
    all: {
      position: getValuesForAll('position'),
      dimensions: {
        offset: getValuesForAll('offset'),
        width: getValuesForAll('width'),
        depth: getValuesForAll('depth'),
      },
    },
  };

  return result;
};

const isGrooveOnEdge = (part: PartItem, edge: keyof PartGrooves) => {
  const edgeGroove = part.grooves?.[edge]?.position;
  return Boolean(edgeGroove) && edgeGroove !== 'none';
};

const cleanDimensions = (dimensions: EdgeGrooveInputType['dimensions']) => {
  return {
    offset: Number(cleanDecimal(dimensions.offset)),
    width: Number(cleanDecimal(dimensions.width)),
    depth: Number(cleanDecimal(dimensions.depth)),
  };
};
