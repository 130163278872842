import {
  Material,
  MaterialGroup,
  MaterialSandwichType,
} from '@cutr/constants/cutlist';
import { PartItem } from '@cutr/constants/cutlist';

import {
  CoreMaterialTypes,
  EdgebandMaterialTypes,
  MATERIAL_LAYER_THICKNESS_UM_THRESHOLD,
  Materials,
} from '@/utils/material-type';

import { buildFuse } from './materialSearch';

let materialsCache: Material[] = [];
export const materialsCached = () => materialsCache.length > 0;

export const cacheMaterials = (materials: Material[]) => {
  materialsCache = [...materials];
  buildFuse();
};

export const getAllMaterials = () => materialsCache;

export const getEdgebandingMaterials = () => {
  return materialsCache.filter((material) =>
    (EdgebandMaterialTypes as Material['type'][]).includes(material.type)
  );
};

export const getCoreMaterials = () => {
  return materialsCache.filter(
    (material) =>
      (CoreMaterialTypes as Material['type'][]).includes(material.type) &&
      material.thicknessUM >= MATERIAL_LAYER_THICKNESS_UM_THRESHOLD
  );
};

export const getCoreMaterialsForGluingAndPressing = () =>
  getCoreMaterials().filter((material) => material.gluingAndPressingAllowed);

export const getHPLMaterials = () => {
  return materialsCache.filter(
    (material) =>
      material.type === Materials.hpl ||
      ((CoreMaterialTypes as Material['type'][]).includes(material.type) &&
        material.thicknessUM < MATERIAL_LAYER_THICKNESS_UM_THRESHOLD)
  );
};

export const getMaterial = (code?: Material['articleCode'] | null) => {
  if (!code) return undefined;
  return materialsCache.find((material) => material.articleCode === code);
};

export const getMaterialById = (id?: Material['id'] | null) => {
  return materialsCache.find((material) => material.id === id);
};

export const groupSearchResultsByVariationGroupAndThickness = (
  materialResults: Material[]
) => {
  const groupedSearchResults = [] as Material[][];
  const usedVariationIds = [] as string[];
  for (const materialResult of materialResults) {
    if (!materialResult.variationGroup) {
      // When a material is not assigned to a variation group, this feature will not work correctly.
      // So we rather throw a warning instead of skipping silently.
      // TODO(shatlyk): Uncomment this line after https://linear.app/cutr/issue/CTR-7389/update-local-seeder-to-create-variations
      // console.warn(
      //   `Material ${materialResult.articleCode} does not have a variation group`
      // );
      continue;
    }
    if (usedVariationIds.includes(materialResult.variationGroup.id)) continue;
    usedVariationIds.push(materialResult.variationGroup.id);

    const variationGroupMaterials = materialsCache.filter(
      (m) => m.variationGroup?.id === materialResult.variationGroup?.id
    );

    // sorted by thickness on UI
    const thicknesses = variationGroupMaterials
      .map((m) => m.thicknessUM)
      .filter((thicknessUM, i, items) => items.indexOf(thicknessUM) === i)
      .sort((a, b) => a - b);

    for (const thickness of thicknesses) {
      groupedSearchResults.push(
        variationGroupMaterials
          .filter((m) => m.thicknessUM === thickness)
          .sort((a, b) => a.lengthMM * a.widthMM - b.lengthMM * b.widthMM)
      );
    }
  }

  return groupedSearchResults;
};

export type MaterialSandwichLayers = Pick<
  PartItem,
  'core1' | 'core2' | 'topHpl' | 'bottomHpl'
>;

export function getSandwichType(
  layers: MaterialSandwichLayers
): MaterialSandwichType | null {
  if (layers.core1 && layers.core2 && layers.topHpl && layers.bottomHpl)
    return 'double-core-double-layer';
  if (layers.core1 && layers.core2 && layers.topHpl) return null;
  if (layers.core1 && layers.core2 && layers.bottomHpl) return null;
  if (layers.core1 && layers.core2) return 'double-core';

  if (layers.core1 && layers.topHpl && layers.bottomHpl)
    return 'single-core-double-layer';
  if (layers.core1 && layers.topHpl) return null;
  if (layers.core1 && layers.bottomHpl) return null;
  if (layers.core1) return 'single-core';

  return null;
}

export function getMaterialsFromMaterialGroup(materialGroup: MaterialGroup) {
  const formatMaterialOutput = (material?: Material) => {
    if (!material) return null;

    return material.id;
  };

  return {
    core1MaterialId: formatMaterialOutput(getMaterial(materialGroup.core1)),
    core2MaterialId: formatMaterialOutput(getMaterial(materialGroup.core2)),
    topHplId: formatMaterialOutput(getMaterial(materialGroup.topHpl)),
    bottomHplId: formatMaterialOutput(getMaterial(materialGroup.bottomHpl)),
    edgebandId: formatMaterialOutput(getMaterial(materialGroup.edgeband)),
    materialSandwichType: getSandwichType(materialGroup),
  };
}

export function getEdgeTrimsFromMaterialGroup(materialGroup: MaterialGroup) {
  return {
    sheetEdgeTrimType: materialGroup.sheetEdgeTrimConfig?.sheetEdgeTrimType,
    length1TrimThicknessMM:
      materialGroup.sheetEdgeTrimConfig?.trimThickness.length1TrimThicknessMM,
    length2TrimThicknessMM:
      materialGroup.sheetEdgeTrimConfig?.trimThickness.length2TrimThicknessMM,
    width1TrimThicknessMM:
      materialGroup.sheetEdgeTrimConfig?.trimThickness.width1TrimThicknessMM,
    width2TrimThicknessMM:
      materialGroup.sheetEdgeTrimConfig?.trimThickness.width2TrimThicknessMM,
  };
}

export function getPricingMaterialsFromPart(part: PartItem) {
  const formatMaterialOutput = (material?: Material) => {
    if (!material) return null;

    return material.id;
  };

  return {
    core1MaterialId: formatMaterialOutput(getMaterial(part.core1)),
    core2MaterialId: formatMaterialOutput(getMaterial(part.core2)),
    topHplId: formatMaterialOutput(getMaterial(part.topHpl)),
    bottomHplId: formatMaterialOutput(getMaterial(part.bottomHpl)),
    materialSandwichType: getSandwichType(part),
    length1EdgebandId: formatMaterialOutput(
      getMaterial(part.edgebanding?.length1)
    ),
    length2EdgebandId: formatMaterialOutput(
      getMaterial(part.edgebanding?.length2)
    ),
    width1EdgebandId: formatMaterialOutput(
      getMaterial(part.edgebanding?.width1)
    ),
    width2EdgebandId: formatMaterialOutput(
      getMaterial(part.edgebanding?.width2)
    ),
    length1EdgeProfile: part.edgeProfile?.length1 || 'none',
    length2EdgeProfile: part.edgeProfile?.length2 || 'none',
    width1EdgeProfile: part.edgeProfile?.width1 || 'none',
    width2EdgeProfile: part.edgeProfile?.width2 || 'none',
  };
}
