import { CutlistEmailData, CutlistOrder } from '@cutr/constants/cutlist';
import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import {
  agentApi,
  PricingData,
  PricingOverrideData,
  UserLeadData,
} from '@/api/backend/agent';

import { Address } from '../../src/api/address';
import { agentKeys } from './keys';

export const useUpdateCutlistAgent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id }: { id: string }) => agentApi.updateCutlist({ id }),
    mutationKey: agentKeys.update,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'cutlist';
        },
        refetchType: 'all',
      });
    },
  });
};

export const useGetCutlistEmail = (id: string) => {
  return useQuery<CutlistEmailData>(getCutlistEmailQuery(id));
};

export const getCutlistEmailQuery = (id: string) => ({
  queryKey: agentKeys.getCutlistEmail(id),
  queryFn: () => agentApi.getCutlistEmail({ id }),
});

export const getSearchOwnerQuery = (searchQuery: string) => ({
  queryKey: agentKeys.searchOwner(searchQuery, true, false),
  queryFn: async () => {
    if (!searchQuery) return [];
    return agentApi.searchOwner({ searchQuery });
  },
});

export const useSearchAgent = (searchQuery: string) => {
  return useQuery({
    queryKey: agentKeys.searchAgent(searchQuery),
    queryFn: () => agentApi.searchAgent({ searchQuery }),
    staleTime: 1000 * 60 * 1,
  });
};

export const useGetPriceForMaterialGroups = (cutlistId: string) => {
  return useMutation({
    mutationKey: agentKeys.getPriceForMaterialGroups(cutlistId),
    mutationFn: ({
      id,
      materialGroups,
      discountAmount,
      discountPercentage,
    }: PricingData) =>
      agentApi.getPriceForMaterialGroups({
        id,
        materialGroups,
        discountAmount,
        discountPercentage,
      }),
  });
};

export const useGetPrice = (cutlistId: string) => {
  return useQuery<CutlistOrder>({
    queryKey: agentKeys.getPrice(cutlistId),
    queryFn: () => agentApi.getPrice(cutlistId),
  });
};

export const useSavePriceOverride = (cutlistId: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: agentKeys.savePriceOverride(cutlistId),
    mutationFn: ({
      id,
      discountAmount,
      discountPercentage,
      markupAmount,
      markupPercentage,
      pricingLineItems,
    }: PricingOverrideData) =>
      agentApi.getPriceOverride({
        // uses the same endpoint as GET, might change later
        id,
        discountAmount,
        discountPercentage,
        markupAmount,
        markupPercentage,
        pricingLineItems,
        save: true,
      }),
    onSuccess: () => {
      queryClient.clear();
    },
  });
};

export const useIsPriceLoading = (cutlistId: string) => {
  return (
    useIsMutating({
      mutationKey: agentKeys.getPriceForMaterialGroups(cutlistId),
    }) > 0
  );
};

export const useCancelCutlist = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => agentApi.cancelCutlist(id),
    mutationKey: agentKeys.cancel,
  });
};

export const useAgentUpdateCutlistAddress = () => {
  return useMutation({
    mutationFn: ({ id, address }: { id: string; address: Address }) =>
      agentApi.updateCutlistDeliveryAddress(id, address),
    mutationKey: agentKeys.updateCutlistAddress,
  });
};

export const useAgentFinaliseQuote = () => {
  return useMutation({
    mutationFn: ({ id }: { id: string }) => agentApi.finaliseQuote(id),
    mutationKey: agentKeys.finaliseQuote,
  });
};

export const useAgentCutlistPatch = (cutlistId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      id,
      fields,
    }: {
      id: string;
      fields: Partial<CutlistOrder>;
    }) => agentApi.patchCutlist({ id, fields }),
    mutationKey: agentKeys.patchCutlist(cutlistId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'cutlist';
        },
        refetchType: 'all',
      });
    },
  });
};

export const useGetAgents = () => {
  return useQuery({
    queryKey: agentKeys.agents,
    queryFn: () => agentApi.getAgents(),
  });
};

export const useCreateUserLead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UserLeadData) => agentApi.createUserLead(data),
    mutationKey: agentKeys.createUserLead,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'userLead';
        },
        refetchType: 'all',
      });
    },
  });
};

export const useUpdateUserLead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      id,
      cutlistId,
      ...data
    }: UserLeadData & { id: string; cutlistId: string }) => {
      const response = await agentApi.updateUserLead({
        id,
        ...data,
        cutlistId,
      });
      return response;
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ['userLead', data.id] });
      await queryClient.invalidateQueries({ queryKey: ['searchOwner'] });
      await queryClient.invalidateQueries({ queryKey: ['cutlistAddress'] });
      await queryClient.invalidateQueries({ queryKey: ['deliveryAddress'] });
    },
  });
};
