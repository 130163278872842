import { MaterialGroup } from '@cutr/constants/cutlist';

import { useFeatureFlag } from './featureFlags';
import { useActiveGroup, useActiveGroupParts } from './materialsGroup';

export const useHasAiSuggestions = () => {
  const group = useActiveGroupAiSuggestions();
  const parts = useActiveGroupParts();

  const isLlmFlowEnabled = useFeatureFlag('invofox-llm-flow');

  if (!isLlmFlowEnabled) return false;

  const partsWithSuggestions = parts.filter(
    (part) => part.aiSuggestions && Object.keys(part.aiSuggestions).length > 0
  );

  return partsWithSuggestions.length > 0 || Object.keys(group).length > 0;
};

export const useActiveGroupAiSuggestions =
  (): MaterialGroup['aiSuggestions'] => {
    const activeGroup = useActiveGroup();

    if (!activeGroup) return {};

    if (Object.keys(activeGroup.aiSuggestions).length === 0) return {};

    return activeGroup.aiSuggestions;
  };
