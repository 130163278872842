import cn from 'classnames';
import React from 'react';

import styles from './Dropdown.module.css';

export interface DropdownOption {
  value: string;
  component: React.FC<{ isSelected?: boolean }>;
  selected?: boolean;
  disabled?: boolean;
}

interface DropdownProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'value' | 'onChange'
  > {
  id: string;
  value?: string;
  options?: DropdownOption[];
  groupedOptions?: Record<string, DropdownOption[]>;
  onSelected?: (value: string) => void;
  onChange?: (value: string) => void;
  readOnly?: boolean;
}

export const Dropdown = ({
  value,
  options,
  groupedOptions,
  id,
  onSelected,
  onChange,
  readOnly = false,
  ...props
}: DropdownProps) => {
  const [expanded, setExpanded] = React.useState(false);
  const [val, setVal] = React.useState((value as string) || '');
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!expanded) return;

    const handleClick = (e: MouseEvent) => {
      if (
        e.target !== dropdownRef.current &&
        !dropdownRef.current?.contains(e.target as HTMLElement)
      ) {
        setExpanded(false);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [expanded]);

  React.useEffect(() => {
    if (!options) return;
    options.forEach((o) => {
      if (o.selected) setVal(o.value);
    });
  }, [options]);

  React.useEffect(() => {
    if (!groupedOptions) return;
    Object.values(groupedOptions)
      .flat()
      .forEach((o) => {
        if (o.selected) setVal(o.value);
      });
  }, [groupedOptions]);

  const handleSelection = (code: string) => {
    if (readOnly) return;
    setVal(code);
    setExpanded(false);
    onSelected?.(code);
    onChange?.(code);
  };

  const Selected = (options || Object.values(groupedOptions || {}).flat()).find(
    (o) => o.value === val
  )?.component;

  return (
    <div
      className={styles.dropdown}
      ref={dropdownRef}
      onKeyDown={(e) => {
        e.preventDefault();
        if (e.key === 'Escape') {
          setExpanded(false);
        }

        if (e.key === 'Enter') {
          const el = e.currentTarget.parentElement?.querySelector(
            '[role="option"][aria-selected="true"]'
          );
          if (!el) return setExpanded((e) => !e);

          const code = el.getAttribute('data-id');
          if (!code) return;

          const disabled = el.getAttribute('aria-disabled');
          if (disabled === 'true') {
            return;
          }

          el.setAttribute('aria-selected', 'false');
          handleSelection(code);
        }

        let els: HTMLElement[] = [];
        let found = -1;
        if (['ArrowDown', 'ArrowUp'].includes(e.key)) {
          setExpanded(true);
          els = [
            ...(e.currentTarget.parentElement?.querySelectorAll(
              '[role="option"]'
            ) || []),
          ] as HTMLElement[];

          for (const el of els) {
            if (el.getAttribute('aria-selected') === 'true') {
              el.setAttribute('aria-selected', 'false');
              found = els.findIndex((ell) => ell === el);
              continue;
            }
          }
        }
        if (e.key === 'ArrowDown') {
          const targetEl = els[found + 1] ? els[found + 1] : els[0];
          targetEl?.setAttribute('aria-selected', 'true');
          targetEl?.scrollIntoView({
            block: 'center',
          });
        }
        if (e.key === 'ArrowUp') {
          const targetEl = els[found - 1]
            ? els[found - 1]
            : els[els.length - 1];
          targetEl?.setAttribute('aria-selected', 'true');
          targetEl?.scrollIntoView({
            block: 'center',
          });
        }
      }}
    >
      <div
        role="button"
        tabIndex={0}
        id={`dropdown-button-${id}`}
        aria-controls={`dropdown-${id}`}
        className={styles.dropdownBox}
        onClick={() => setExpanded(!expanded)}
        {...props}
      >
        <div className="flexAlign opposites">
          {Selected && <Selected />}
          <div
            className={cn(styles.ggChevronDown, expanded && styles.ggChevronUp)}
          />
        </div>
      </div>
      {expanded && (
        <div className={styles.dropdownWrapper}>
          {options && (
            <div
              className={styles.dropdownOptions}
              role="listbox"
              id={`dropdown-${id}`}
            >
              {options.map((o) => (
                <div
                  role="option"
                  tabIndex={-1}
                  aria-selected={val === o.value}
                  aria-disabled={o.disabled}
                  key={o.value}
                  data-id={o.value}
                  onClick={() => o.disabled || handleSelection(o.value)}
                >
                  <o.component isSelected={val === o.value} />
                </div>
              ))}
            </div>
          )}

          {groupedOptions && (
            <div
              className={styles.dropdownOptionsGrouped}
              role="listbox"
              id={`dropdown-${id}`}
            >
              {Object.entries(groupedOptions).map(([key, componentList]) => {
                return (
                  <div key={key} className={styles.dropdownOptionsGroup}>
                    <div>
                      <strong>{key}</strong>
                    </div>
                    {componentList.map((o) => (
                      <div
                        role="option"
                        tabIndex={-1}
                        aria-selected={val === o.value}
                        aria-disabled={o.disabled}
                        key={o.value}
                        data-id={o.value}
                        onClick={() => o.disabled || handleSelection(o.value)}
                      >
                        <o.component isSelected={val === o.value} />
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
