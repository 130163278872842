import { EdgeGroove, Material } from '@cutr/constants/cutlist';
import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { useSelectedPartsContext } from './SelectedPartsContext';

export type Edges = {
  length1: boolean;
  length2: boolean;
  width1: boolean;
  width2: boolean;
  all: boolean;
};

type EdgeSelectorState = Record<
  keyof Omit<Edges, 'all'>,
  'on' | 'off' | 'mixed'
>;

interface EdgeSelectorContextType {
  edgesToApply: EdgeSelectorState;
  setEdgesToApply: Dispatch<SetStateAction<EdgeSelectorState>>;
  clear: () => void;
}

const INITIAL_EDGES_TO_APPLY: EdgeSelectorState = {
  length1: 'off',
  length2: 'off',
  width1: 'off',
  width2: 'off',
};

const EdgeSelectorContext = createContext<EdgeSelectorContextType | null>(null);

export const EdgeSelectorProvider: React.FC<{
  children: ReactNode;
  initialEdgesToApply?: EdgeSelectorState;
}> = ({ children, initialEdgesToApply = INITIAL_EDGES_TO_APPLY }) => {
  const [edgesToApply, setEdgesToApply] =
    useState<EdgeSelectorState>(initialEdgesToApply);

  const clear = () => {
    setEdgesToApply(INITIAL_EDGES_TO_APPLY);
  };

  return (
    <EdgeSelectorContext.Provider
      value={{
        edgesToApply,
        setEdgesToApply,
        clear,
      }}
    >
      {children}
    </EdgeSelectorContext.Provider>
  );
};

export const useEdgeSelector = () => {
  const context = useContext(EdgeSelectorContext);
  if (!context) {
    throw new Error(
      'useEdgeSelector must be used within an EdgeSelectorProvider'
    );
  }
  return context;
};

interface EdgebandingContextType {
  edgeband: Material['articleCode'];
  edgebandPerEdge: Record<keyof Edges, Material['articleCode']>;
  roundedEdgeband: boolean;
  roundedEdgebandPerEdge: Record<keyof Edges, boolean>;
  setEdgeband: (edgeband: Material['articleCode'], edge: keyof Edges) => void;
  setRoundedEdgeband: (rounded: boolean, side: keyof Edges) => void;
  clearState: () => void;
}

const EdgebandingContext = createContext<EdgebandingContextType | null>(null);

type InitialEdgebandingState = Record<keyof Edges, Material['articleCode']> & {
  roundedEdgeband: Record<keyof Edges, boolean>;
};
const INITIAL_EDGEBANDING_STATE: InitialEdgebandingState = {
  length1: '',
  length2: '',
  width1: '',
  width2: '',
  all: '',
  roundedEdgeband: {
    length1: false,
    length2: false,
    width1: false,
    width2: false,
    all: false,
  },
};

export const EdgebandingProvider: React.FC<{
  children: ReactNode;
  initialState?: InitialEdgebandingState;
}> = ({ children, initialState = INITIAL_EDGEBANDING_STATE }) => {
  const { disableAll } = useSelectedPartsContext();
  const [edgeband, setEdgeband] = useState<Material['articleCode']>(
    initialState.all
  );
  const [length1Edgeband, setLength1Edgeband] = useState<
    Material['articleCode']
  >(initialState.length1);
  const [length2Edgeband, setLength2Edgeband] = useState<
    Material['articleCode']
  >(initialState.length2);
  const [width1Edgeband, setWidth1Edgeband] = useState<Material['articleCode']>(
    initialState.width1
  );
  const [width2Edgeband, setWidth2Edgeband] = useState<Material['articleCode']>(
    initialState.width2
  );
  const [roundedEdgeband, setRoundedEdgeband] = useState(
    initialState.roundedEdgeband.all
  );
  const [length1RoundedEdgeband, setLength1RoundedEdgeband] = useState(
    initialState.roundedEdgeband.length1
  );
  const [length2RoundedEdgeband, setLength2RoundedEdgeband] = useState(
    initialState.roundedEdgeband.length2
  );
  const [width1RoundedEdgeband, setWidth1RoundedEdgeband] = useState(
    initialState.roundedEdgeband.width1
  );
  const [width2RoundedEdgeband, setWidth2RoundedEdgeband] = useState(
    initialState.roundedEdgeband.width2
  );

  const setEdgebandPerEdge = (
    edgeband: Material['articleCode'],
    side: keyof Edges
  ) => {
    switch (side) {
      case 'length1':
        setLength1Edgeband(edgeband);
        break;
      case 'length2':
        setLength2Edgeband(edgeband);
        break;
      case 'width1':
        setWidth1Edgeband(edgeband);
        break;
      case 'width2':
        setWidth2Edgeband(edgeband);
        break;
      case 'all':
        setEdgeband(edgeband);
        break;
    }

    if (edgeband === '') {
      setRoundedEdgebandPerEdge(false, side);
    }
  };

  const setRoundedEdgebandPerEdge = (rounded: boolean, side: keyof Edges) => {
    switch (side) {
      case 'length1':
        setLength1RoundedEdgeband(rounded);
        break;
      case 'length2':
        setLength2RoundedEdgeband(rounded);
        break;
      case 'width1':
        setWidth1RoundedEdgeband(rounded);
        break;
      case 'width2':
        setWidth2RoundedEdgeband(rounded);
        break;
      case 'all':
        setRoundedEdgeband(rounded);
        break;
    }
  };

  const clearState = () => {
    ['length1', 'length2', 'width1', 'width2', 'all'].forEach((side) => {
      setRoundedEdgebandPerEdge(false, side as keyof Edges);
      setEdgebandPerEdge('', side as keyof Edges);
    });
    disableAll();
  };

  const edgebandPerEdge = {
    length1: length1Edgeband,
    length2: length2Edgeband,
    width1: width1Edgeband,
    width2: width2Edgeband,
    all: edgeband,
  };

  const roundedEdgebandPerEdge = {
    length1: length1RoundedEdgeband,
    length2: length2RoundedEdgeband,
    width1: width1RoundedEdgeband,
    width2: width2RoundedEdgeband,
    all: roundedEdgeband,
  };

  return (
    <EdgebandingContext.Provider
      value={{
        edgeband,
        setEdgeband: setEdgebandPerEdge,
        edgebandPerEdge,
        roundedEdgeband,
        roundedEdgebandPerEdge,
        setRoundedEdgeband: setRoundedEdgebandPerEdge,
        clearState,
      }}
    >
      {children}
    </EdgebandingContext.Provider>
  );
};

export const useEdgebanding = () => {
  const context = useContext(EdgebandingContext);
  if (!context) {
    throw new Error(
      'useEdgebanding must be used within an EdgebandingProvider'
    );
  }
  return context;
};

export interface EdgeGrooveInputType {
  position: EdgeGroove['position'] | null;
  dimensions: {
    offset: string;
    width: string;
    depth: string;
  };
}
interface EdgeGroovesContextType {
  groovesPerEdge: Record<keyof Edges, EdgeGrooveInputType>;
  setEdgeGroove: (edge: keyof Edges, value: EdgeGrooveInputType) => void;
  clearState: () => void;
}

const EdgeGroovesContext = createContext<EdgeGroovesContextType | null>(null);

const INITIAL_EDGE_GROOVES_STATE: Record<keyof Edges, EdgeGrooveInputType> = {
  length1: {
    position: 'none',
    dimensions: { offset: '', width: '', depth: '' },
  },
  length2: {
    position: 'none',
    dimensions: { offset: '', width: '', depth: '' },
  },
  width1: {
    position: 'none',
    dimensions: { offset: '', width: '', depth: '' },
  },
  width2: {
    position: 'none',
    dimensions: { offset: '', width: '', depth: '' },
  },
  all: {
    position: 'none',
    dimensions: { offset: '', width: '', depth: '' },
  },
};

export const EdgeGroovesProvider: React.FC<{
  children: ReactNode;
  initialState?: Record<keyof Edges, EdgeGrooveInputType>;
}> = ({ children, initialState = INITIAL_EDGE_GROOVES_STATE }) => {
  const { disableAll } = useSelectedPartsContext();
  const { clear: clearEdgeSelector } = useEdgeSelector();
  const [length1Position, setLength1Position] = useState<
    EdgeGrooveInputType['position']
  >(initialState.length1.position);
  const [length1Dimensions, setLength1Dimensions] = useState(
    initialState.length1.dimensions
  );
  const [length2Position, setLength2Position] = useState<
    EdgeGrooveInputType['position']
  >(initialState.length2.position);
  const [length2Dimensions, setLength2Dimensions] = useState(
    initialState.length2.dimensions
  );
  const [width1Position, setWidth1Position] = useState<
    EdgeGrooveInputType['position']
  >(initialState.width1.position);
  const [width1Dimensions, setWidth1Dimensions] = useState(
    initialState.width1.dimensions
  );
  const [width2Position, setWidth2Position] = useState<
    EdgeGrooveInputType['position']
  >(initialState.width2.position);
  const [width2Dimensions, setWidth2Dimensions] = useState(
    initialState.width2.dimensions
  );

  const [allPosition, setAllPosition] = useState<
    EdgeGrooveInputType['position']
  >(initialState.all.position);
  const [allDimensions, setAllDimensions] = useState(
    initialState.all.dimensions
  );

  const setEdgeGroove = (edge: keyof Edges, value: EdgeGrooveInputType) => {
    switch (edge) {
      case 'length1':
        setLength1Position(value.position);
        setLength1Dimensions(value.dimensions);
        break;
      case 'length2':
        setLength2Position(value.position);
        setLength2Dimensions(value.dimensions);
        break;
      case 'width1':
        setWidth1Position(value.position);
        setWidth1Dimensions(value.dimensions);
        break;
      case 'width2':
        setWidth2Position(value.position);
        setWidth2Dimensions(value.dimensions);
        break;
      case 'all':
        setAllPosition(value.position);
        setAllDimensions(value.dimensions);
        break;
    }
  };

  const groovesPerEdge = {
    length1: { position: length1Position, dimensions: length1Dimensions },
    length2: { position: length2Position, dimensions: length2Dimensions },
    width1: { position: width1Position, dimensions: width1Dimensions },
    width2: { position: width2Position, dimensions: width2Dimensions },
    all: { position: allPosition, dimensions: allDimensions },
  };

  const clearState = () => {
    setLength1Position(() => 'none');
    setLength1Dimensions(() => ({ offset: '', width: '', depth: '' }));
    setLength2Position(() => 'none');
    setLength2Dimensions(() => ({ offset: '', width: '', depth: '' }));
    setWidth1Position(() => 'none');
    setWidth1Dimensions(() => ({ offset: '', width: '', depth: '' }));
    setWidth2Position(() => 'none');
    setWidth2Dimensions(() => ({ offset: '', width: '', depth: '' }));
    setAllPosition(() => 'none');
    setAllDimensions(() => ({ offset: '', width: '', depth: '' }));
    disableAll();
    clearEdgeSelector();
  };

  return (
    <EdgeGroovesContext.Provider
      value={{
        groovesPerEdge,
        setEdgeGroove,
        clearState,
      }}
    >
      {children}
    </EdgeGroovesContext.Provider>
  );
};

export const useEdgeGrooves = () => {
  const context = useContext(EdgeGroovesContext);
  if (!context) {
    throw new Error(
      'useEdgeGrooves must be used within an EdgeGroovesProvider'
    );
  }
  return context;
};
