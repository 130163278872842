import {
  CutlistEmailData,
  CutlistMaterialGroup,
  CutlistOrder,
  CutlistOrderStatus,
  CutlistOrderSummary,
  PricingLineItem,
  UserLead,
} from '@cutr/constants/cutlist';
import { BackendCutlistGroup } from '@cutr/constants/cutlist-backend';

import { Address } from '../address';
import {
  api,
  ApiError,
  checkError,
  cutlistUpdateBodyData,
  getAddress,
  logError,
} from './';

export type GetCutlistsParams = {
  pageIndex: number;
  pageSize: number;
  search?: string;
  statuses?: CutlistOrderStatus[];
  createdAfter?: string;
  createdBefore?: string;
  submittedAfter?: string;
  submittedBefore?: string;
};

export type PriceDataBase = {
  id: string;
  discountAmount?: number;
  discountPercentage?: number;
  markupAmount?: number;
  markupPercentage?: number;
};

export type PricingData = {
  materialGroups: BackendCutlistGroup[];
} & PriceDataBase;

export type PricingOverrideData = {
  pricingLineItems?: PricingLineItem[];
  save?: boolean;
} & PriceDataBase;

export type PaginatedData<T> = {
  rows: T[];
  totalPages: number;
  totalRows: number;
};

export const checkAgentError = async (
  res: Response,
  redirectToLogin = true
) => {
  if (!res.ok) {
    const json = await res.json().catch(() => ({}));

    if (redirectToLogin && json.statusCode === 401) {
      localStorage.removeItem('cutlist-auth');
      window.location.href = `/admin?redirect=${encodeURIComponent(
        window.location.pathname
      )}`;
      return res;
    }

    throw new ApiError(json.message || res.statusText, res.status, json);
  }

  return res;
};

export interface UserLeadData {
  email: string;
  companyName?: string;
  name?: string;
  clientNumber?: string;
  pricingCategory?: string;
  customerType?: string;
  address?: {
    name?: string;
    line1: string;
    line2?: string;
    city: string;
    postalCode: string;
    country: string;
    phone?: string;
  };
}

export const agentApi = {
  async login(email: string, password: string) {
    const body = JSON.stringify({ email, password });
    return fetch(api.createBaseUrl('', '/v2/sessions'), {
      method: 'POST',
      body,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkAgentError)
      .then((res) => res.json())
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  async devLogin() {
    return fetch(api.createBaseUrl('', '/v2/cutlist/dev-login-agent'), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkAgentError)
      .then((res) => res.json())
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  async sessionToToken() {
    return fetch(api.createUrl('session-to-token'), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  },
  async getCutlists(
    params: GetCutlistsParams
  ): Promise<PaginatedData<CutlistOrderSummary>> {
    const urlParams = new URLSearchParams({
      pageIndex: params.pageIndex.toString(),
      pageSize: params.pageSize.toString(),
    });

    for (const k of [
      'search',
      'statuses',
      'createdAfter',
      'createdBefore',
      'submittedAfter',
      'submittedBefore',
      'agentIds',
    ]) {
      const key = k as keyof GetCutlistsParams;
      if (params[key]) {
        urlParams.set(key, params[key] as string);
      }
    }

    return fetch(api.createUrl(`agent/orders?${urlParams.toString()}`), {
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data, totalPages, totalRows }) => ({
        rows: data.cutlists,
        totalPages,
        totalRows,
      }))
      .catch(logError);
  },
  async updateCutlist({
    id,
  }: {
    id: string;
    markQuoteReady?: boolean;
  }): Promise<CutlistOrder> {
    return fetch(api.createUrl(`agent/orders/${id}`), {
      method: 'PUT',
      body: JSON.stringify({
        ...cutlistUpdateBodyData(),
      }),
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  async getPriceForMaterialGroups({
    id,
    materialGroups,
    discountAmount,
    discountPercentage,
  }: PricingData): Promise<CutlistOrder> {
    const body = JSON.stringify({
      materialGroups,
      discountAmount,
      discountPercentage,
    });
    return fetch(api.createUrl(`agent/orders/${id}/price`), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  async getPrice(id: string): Promise<CutlistOrder> {
    return fetch(api.createUrl(`agent/orders/${id}/price`), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  async getCutlistEmail({ id }: { id: string }): Promise<CutlistEmailData> {
    return fetch(api.createUrl(`agent/orders/${id}/email`), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  async getPriceOverride({
    id,
    discountAmount,
    discountPercentage,
    markupAmount,
    markupPercentage,
    pricingLineItems,
    save,
  }: PricingOverrideData): Promise<CutlistOrder> {
    const body = JSON.stringify({
      discountAmount,
      discountPercentage,
      markupAmount,
      markupPercentage,
      pricingLineItems,
      save,
    });
    return fetch(api.createUrl(`agent/orders/${id}/pricing-line-items`), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  getCutlistZip: (id: string): Promise<Blob> => {
    return fetch(api.createUrl(`agent/orders/${id}/downloads/zip`), {
      headers: { 'Content-Type': 'blob', ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.blob())
      .catch(logError);
  },
  getCutlistHpo: (id: string): Promise<Blob> => {
    return fetch(api.createUrl(`agent/orders/${id}/downloads/hpo`), {
      headers: { 'Content-Type': 'blob', ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.blob())
      .catch(logError);
  },
  getCutlistSap: (id: string): Promise<Blob> => {
    return fetch(api.createUrl(`agent/orders/${id}/downloads/sap`), {
      headers: { 'Content-Type': 'blob', ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.blob())
      .catch(logError);
  },
  getCutlistPricingPDF: (id: string): Promise<Blob> => {
    return fetch(api.createUrl(`agent/orders/${id}/downloads/pricing-pdf`), {
      headers: { 'Content-Type': 'blob', ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.blob())
      .catch(logError);
  },

  getCutlistPricingSheet: (id: string): Promise<Blob> => {
    return fetch(api.createUrl(`agent/orders/${id}/downloads/pricing-sheet`), {
      headers: { 'Content-Type': 'blob', ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.blob())
      .catch(logError);
  },

  createCutlist: (title: string): Promise<CutlistOrder> => {
    const body = JSON.stringify({ title });
    const url = 'agent/orders/new';

    return fetch(api.createUrl(url), {
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      body,
      credentials: 'include',
      method: 'POST',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  searchOwner: ({
    searchQuery,
  }: {
    searchQuery: string;
  }): Promise<UserLead[]> => {
    return fetch(api.createUrl(`agent/search-customer?search=${searchQuery}`), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },

  searchAgent: ({ searchQuery }: { searchQuery: string }) => {
    return fetch(api.createUrl(`agent/search-agent?search=${searchQuery}`), {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },

  assignCutlistOwner: (email: string, cutlistId: string) => {
    const body = JSON.stringify({
      email,
    });
    return fetch(api.createUrl(`agent/orders/${cutlistId}/assign-owner`), {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },

  assignCutlistAgent: (email: string, cutlistId: string) => {
    const body = JSON.stringify({
      email,
    });
    return fetch(api.createUrl(`agent/orders/${cutlistId}/assign-agent`), {
      method: 'POST',
      body,
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },

  cancelCutlist: (id: string): Promise<void> => {
    const url = `agent/orders/${id}/cancel`;

    return fetch(api.createUrl(url), {
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      credentials: 'include',
      method: 'POST',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(() => {})
      .catch(logError);
  },
  updateCutlistDeliveryAddress: async (id: string, address: Address) => {
    const body = JSON.stringify(getAddress(address));
    return fetch(api.createUrl(`agent/orders/${id}/address`), {
      method: 'POST',
      body,
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(() => {})
      .catch(logError);
  },

  finaliseQuote: (id: string) => {
    return fetch(api.createUrl(`agent/orders/${id}/finalise-quote`), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(() => {})
      .catch(logError);
  },

  cancelAnalysis: (id: string): Promise<CutlistOrderSummary> => {
    return fetch(api.createUrl(`agent/orders/${id}/cancel-analysis`), {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },

  async patchCutlist({
    id,
    fields,
  }: {
    id: string;
    fields: Partial<CutlistOrder>;
  }): Promise<CutlistOrder> {
    return fetch(api.createUrl(`agent/orders/${id}`), {
      method: 'PATCH',
      body: JSON.stringify({
        ...fields,
      }),
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  createMaterialGroup: (
    cutlistId: string,
    materialGroup: BackendCutlistGroup
  ): Promise<CutlistMaterialGroup[]> => {
    const body = JSON.stringify(materialGroup);

    return fetch(api.createUrl(`agent/orders/${cutlistId}/material-groups`), {
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      body,
      credentials: 'include',
      method: 'POST',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  updateMaterialGroup: (
    cutlistId: string,
    materialGroupId: string,
    materialGroup: BackendCutlistGroup
  ): Promise<CutlistMaterialGroup[]> => {
    const body = JSON.stringify(materialGroup);

    return fetch(
      api.createUrl(
        `agent/orders/${cutlistId}/material-groups/${materialGroupId}`
      ),
      {
        headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
        body,
        credentials: 'include',
        method: 'PATCH',
      }
    )
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  deleteMaterialGroup: (
    cutlistId: string,
    materialGroupId: string
  ): Promise<CutlistMaterialGroup[]> => {
    return fetch(
      api.createUrl(
        `agent/orders/${cutlistId}/material-groups/${materialGroupId}`
      ),
      {
        headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
        credentials: 'include',
        method: 'DELETE',
      }
    )
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },

  getAgents: () => {
    return fetch(api.createUrl('agent/agents'), {
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  duplicateCutlist: (cutlistId: string): Promise<CutlistOrder> => {
    return fetch(api.createUrl(`agent/orders/${cutlistId}/copy`), {
      headers: { 'Content-Type': 'application/json', ...api.getHeaders() },
      credentials: 'include',
      method: 'POST',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
  createUserLead: async (data: UserLeadData): Promise<UserLead> => {
    return fetch(api.createUrl('agent/user-leads'), {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        ...api.getHeaders(),
      },
      credentials: 'include',
    })
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },

  async updateUserLead({
    id,
    cutlistId,
    ...data
  }: UserLeadData & { id: string; cutlistId: string }): Promise<UserLead> {
    return fetch(
      api.createUrl(`agent/user-leads/${id}?cutlistId=${cutlistId}`),
      {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          ...api.getHeaders(),
        },
        credentials: 'include',
      }
    )
      .then(checkError)
      .then((res) => res.json())
      .then(({ data }) => data)
      .catch(logError);
  },
};
