export function cleanNumberInput(value: string) {
  const cleanValue = Number(String(value).replace(/\D/g, ''));
  return cleanValue > 0 ? cleanValue : '';
}

export function cleanDecimal(value: string): string {
  if (!value) {
    return '';
  }

  const r = String(value)
    .replace(/[^\d.,]/g, '')
    .replace(',', '.');

  return r;
}
