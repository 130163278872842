import { PartAiSuggestions, PartItem } from '@cutr/constants/cutlist';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from '@/api/featureFlags';
import { Icon, Sparkles } from '@/primitives/Icons';

import styles from './AiSuggestions.module.css';

export const AiSuggestions = ({ part }: { part: PartItem }) => {
  const { t } = useTranslation();
  const hasLlmFlowEnabled = useFeatureFlag('invofox-llm-flow');

  if (!hasLlmFlowEnabled) return null;
  if (!part.aiSuggestions) return <td></td>;

  const suggestions = part.aiSuggestions as Partial<PartAiSuggestions>;

  // Group by category and side
  const groupedSuggestions = {
    core: null,
    notes: suggestions.notes
      ? t('cutlist-form.notes') + ': ' + suggestions.notes
      : null,

    edgebands: {
      l1: suggestions.length1Edgeband,
      l2: suggestions.length2Edgeband,
      w1: suggestions.width1Edgeband,
      w2: suggestions.width2Edgeband,
    },

    grooves: {
      l1: suggestions.length1Groove,
      l2: suggestions.length2Groove,
      w1: suggestions.width1Groove,
      w2: suggestions.width2Groove,
    },

    edgeProfiles: {
      l1: suggestions.length1EdgeProfile,
      l2: suggestions.length2EdgeProfile,
      w1: suggestions.width1EdgeProfile,
      w2: suggestions.width2EdgeProfile,
    },
  };

  // Combine all groups
  const formattedSuggestions = [
    groupedSuggestions.core,
    formatGroupWithMerging(
      groupedSuggestions.edgebands,
      t('cutlist-form.field.aiSuggestions.edgebands'),
      t
    ),
    formatGroupWithMerging(
      groupedSuggestions.grooves,
      t('cutlist-form.field.aiSuggestions.grooves'),
      t
    ),
    formatGroupWithMerging(
      groupedSuggestions.edgeProfiles,
      t('cutlist-form.field.aiSuggestions.edgeProfiles'),
      t
    ),
    groupedSuggestions.notes,
  ]
    .filter(Boolean)
    .join('. ');

  return (
    <td>
      <div className={styles.container}>
        {formattedSuggestions && (
          <Icon
            style={{
              minWidth: '1rem',
            }}
            icon={<Sparkles />}
            color="var(--blue-5)"
          />
        )}
        {formattedSuggestions}
      </div>
    </td>
  );
};

// Format each group with merging of similar values
export const formatGroupWithMerging = (
  group: Record<string, string | undefined>,
  label: string,
  t: TFunction
) => {
  if (!Object.values(group).some(Boolean)) return null;

  // Group sides by their values
  const valueToSides: Record<string, string[]> = {};

  Object.entries(group).forEach(([side, value]) => {
    if (!value) return;

    if (!valueToSides[value]) {
      valueToSides[value] = [];
    }
    valueToSides[value].push(side);
  });

  // Format the merged groups
  const mergedItems = Object.entries(valueToSides)
    .map(([value, sides]) => {
      return `${sides
        .map((side) => t(`cutlist-form.field.edgebanding.sides.${side}`))
        .join(', ')}: ${value}`;
    })
    .join(', ');

  return `${label}: [${mergedItems}]`;
};
