import { MaterialGroup } from '@cutr/constants/cutlist';
import { useTranslation } from 'react-i18next';

import { useCurrentGroupErrors, useCurrentGroupPartErrors } from '@/api/errors';
import {
  setGroupAndParts,
  useCreateGroup,
  useSaveGroupChanges,
} from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';
import { Button } from '@/primitives/Button';
import { Cart, Icon, Save } from '@/primitives/Icons';
import { useCurrentFeatures } from '@/theme';
import { getTrimConfigByType } from '@/utils/features';

import MaterialGroupCard from '../PartTableCommon/MaterialGroupCard';
import { MaterialGroupTypeSelect } from '../PartTableCommon/MaterialGroupTypeSelect';
import { MaterialUpdate } from '../PartTableCommon/types';
import styles from './MaterialGroupHeader.module.css';

export const MaterialGroupHeader = ({ group }: { group: MaterialGroup }) => {
  return <MaterialSelectionHeader group={group} />;
};

const MaterialSelectionHeader = ({ group }: { group: MaterialGroup }) => {
  const { t } = useTranslation();
  const {
    supportedSheetEdgeTrims,
    defaultSheetEdgeTrimTypeForSheetGroup,
    defaultSheetEdgeTrimTypeForPartGroup,
  } = useCurrentFeatures();

  const { addPart } = useCutlistState();
  const groupParts = useCutlistState((state) =>
    state.parts.filter((part) => part.groupId === group.id)
  );

  type K = keyof MaterialGroup;
  const onMaterialGroupUpdate: MaterialUpdate = (fields) => {
    let updatedGroup = { ...group, ...fields } as MaterialGroup;

    const updatedKeys = Object.keys(fields) as K[];
    if (updatedKeys.includes('core1' as K)) {
      // update trim by group type
      const sheetEdgeTrimConfig = getTrimConfigByType(
        supportedSheetEdgeTrims,
        updatedGroup.type === 'sheets-only'
          ? defaultSheetEdgeTrimTypeForSheetGroup
          : defaultSheetEdgeTrimTypeForPartGroup
      );
      updatedGroup = { ...updatedGroup, sheetEdgeTrimConfig };
    }

    setGroupAndParts(updatedGroup);

    // add part if no parts in group
    if (!groupParts.length && updatedGroup.type && updatedGroup.core1)
      addPart(updatedGroup);
  };

  const onMaterialRemoval = (field: K) => {
    onMaterialGroupUpdate({
      [field]: null,
      automaticSheetSizeMaterials: [],
      sheetSizeSelection: 'manual',
    });
  };

  return (
    <>
      <div className={styles.materialGroupHeader}>
        <h3>{t('agent.quoteFlow.materialSettings')}</h3>
        <SaveMaterialGroupButton group={group} />
      </div>
      <div className={styles.materialGroup}>
        <div className={styles.materialsList}>
          <MaterialGroupCard
            i18nKey="core"
            field="core1"
            group={group}
            onUpdateMaterial={onMaterialGroupUpdate}
            onRemoveMaterial={onMaterialRemoval}
          />
        </div>

        <MaterialGroupTypeSelect group={group} />
      </div>
    </>
  );
};

const SaveMaterialGroupButton = ({ group }: { group: MaterialGroup }) => {
  const { t } = useTranslation();

  const currentGroupPartErrors = useCurrentGroupPartErrors();
  const currentGroupErrors = useCurrentGroupErrors();

  const [createMaterialGroup, canCreateMaterialGroup] = useCreateGroup({
    group,
  });
  const [saveMaterialGroup, canSaveMaterialGroup] = useSaveGroupChanges({
    group,
  });

  const hasErrors =
    currentGroupPartErrors.length > 0 || currentGroupErrors.length > 0;

  let addToOrderTooltip: undefined | string = undefined;
  if (!group.core1) {
    addToOrderTooltip = t('agent.tooltip.noPartCreated');
  }
  if (hasErrors) {
    addToOrderTooltip = t('agent.tooltip.addMaterialErrors');
  }

  if (group.ulid) {
    return (
      <Button
        className="smaller pill"
        disabled={hasErrors || !canSaveMaterialGroup}
        icon={<Icon icon={<Save />} />}
        onClick={saveMaterialGroup}
        style={{
          // @ts-ignore
          '--button-bkg': 'var(--agent-accent)',
        }}
      >
        <strong>{t('agent.saveChanges')}</strong>
      </Button>
    );
  }

  return (
    <Button
      className="smaller pill"
      disabled={hasErrors || !canCreateMaterialGroup}
      tooltip={addToOrderTooltip}
      icon={<Icon icon={<Cart />} />}
      onClick={createMaterialGroup}
    >
      <strong>{t('agent.addToOrder')}</strong>
    </Button>
  );
};
