import { PartItem } from '@cutr/constants/cutlist';
import React, { createContext, useContext, useState } from 'react';

import { useActiveGroup } from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';

interface SelectedPartsContextType {
  selectedParts: PartItem[];
  togglePartSelection: (part: PartItem) => void;
  disableAll: () => void;
  enableAll: () => void;
}

const SelectedPartsContext = createContext<
  SelectedPartsContextType | undefined
>(undefined);

export const SelectedPartsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedParts, setSelectedParts] = useState<PartItem[]>([]);
  const activeGroup = useActiveGroup();
  const parts = useCutlistState((state) => state.parts);
  const activeGroupParts = parts.filter((p) => p.groupId === activeGroup?.id);

  React.useEffect(() => {
    setSelectedParts(() => []);
  }, [activeGroup]);

  const togglePartSelection = (part: PartItem) => {
    setSelectedParts((prev) => {
      if (prev.find((p) => p.id === part.id)) {
        return prev.filter((p) => p.id !== part.id);
      }
      return [...prev, part];
    });
  };

  const disableAll = () => {
    setSelectedParts(() => []);
  };

  const enableAll = () => {
    setSelectedParts(() => activeGroupParts ?? []);
  };

  return (
    <SelectedPartsContext.Provider
      value={{
        selectedParts,
        togglePartSelection,
        disableAll,
        enableAll,
      }}
    >
      {children}
    </SelectedPartsContext.Provider>
  );
};

export const useSelectedPartsContext = () => {
  const context = useContext(SelectedPartsContext);
  if (context === undefined) {
    throw new Error(
      'useSelectedPartsContext must be used within a SelectedPartsProvider'
    );
  }
  return context;
};
