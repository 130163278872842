import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMaterialGroupState } from '@/api/materialsGroup';
import { useCutlistState } from '@/api/store';

type AiSuggestionWarning = {
  materialGroupId: string;
  warning: string;
};

export const useAiSuggestionWarnings = (): AiSuggestionWarning[] => {
  const { t } = useTranslation();

  const groups = useMaterialGroupState((state) => state.groups);
  const parts = useCutlistState((state) => state.parts);

  const groupToWarnings = React.useMemo(() => {
    const result: AiSuggestionWarning[] = [];
    groups.forEach((group) => {
      if (group.aiSuggestions['core1'] && !group.core1) {
        result.push({
          materialGroupId: group.id,
          warning: t('agent.warnings.core1Error'),
        });
      }
    });

    return result;
  }, [groups, parts]);

  return groupToWarnings;
};
